import React from 'react';
import SimpleService from '@Common/SimpleService';

export default function StaplesProducts() {
  return (
    <SimpleService
      unitName='product'
      title='Staples Products Scraper'
      subTitle='Returns product listings'
      serviceName='staples_products'
      learnMoreUrl='https://outscraper.com/staples-scraper/'
      apiTag='Others/paths/~1staples~1products/get'
      queriesLabel='Product, category or search URLs'
      queriesPlaceholder='https://www.staples.com/HP-63-Ink-Cartridge/product_1981738&#10;https://www.staples.com/office-chairs/cat_CL167289&#10;https://www.staples.com/search?query=laptop'
    />
  );
}
