import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import PremiumFeatureTooltip from '@shared/Components/PremiumFeatureTooltip';

const { Item } = Form;
const { Option } = Select;

export default function PreferredContactsItem({ value, onChange, isPaidUser }) {
  const { preferred_contacts } = value;
  const { t } = useTranslation();

  const labelWithTooltip = (
    <PremiumFeatureTooltip isPaidUser={!isPaidUser === false}>
      <span>{t('title.preferredContacts', 'Preferred contacts')}</span>
    </PremiumFeatureTooltip>
  );

  return (
    <Item label={labelWithTooltip} className='preferred-contacts'>
      <Select
        disabled={!isPaidUser}
        allowClear
        placeholder={t('title.any')}
        mode='tags'
        maxTagCount={2}
        tokenSeparators={[',']}
        value={preferred_contacts}
        onChange={(v) => onChange({ preferred_contacts: v })}
      >
        <Option key='decision makers' value='decision makers'><Trans i18nKey='title.decisionMakers' /></Option>
        <Option key='influencers' value='influencers'><Trans i18nKey='title.influencers' /></Option>
        <Option key='procurement/purchasing' value='procurement/purchasing'><Trans i18nKey='title.procurementOrPurchasing' /></Option>
        <Option key='technical' value='technical'><Trans i18nKey='title.technical' /></Option>
        <Option key='finance' value='finance'><Trans i18nKey='title.finance' /></Option>
        <Option key='operations' value='operations'><Trans i18nKey='title.operations' /></Option>
        <Option key='marketing' value='marketing'><Trans i18nKey='title.marketing' /></Option>
        <Option key='sales' value='sales'><Trans i18nKey='title.sales' /></Option>
        <Option key='maintenance' value='maintenance'><Trans i18nKey='title.maintenance' /></Option>
        <Option key='human resources' value='human resources'><Trans i18nKey='title.hr' /></Option>
        <Option key='legal and compliance' value='legal and compliance'><Trans i18nKey='title.legalAndCompliances' /></Option>
        <Option key='supply chain/logistics' value='supply chain/logistics'><Trans i18nKey='title.supplyChainOrLogistics' /></Option>
        <Option key='education/training' value='education/training'><Trans i18nKey='title.educationTraining' /></Option>
      </Select>
    </Item>
  );
}

PreferredContactsItem.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isPaidUser: PropTypes.bool,
};
