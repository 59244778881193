import React from 'react';
import SimpleService from '@Common/SimpleService';

export default function SamsClubProducts() {
  return (
    <SimpleService
      unitName='product'
      title='Sams Club Products Scraper'
      subTitle='Returns product listings'
      serviceName='samsclub_products'
      learnMoreUrl='https://outscraper.com/samsclub-scraper/'
      apiTag='SamsClub/paths/~1samsclub~1products/get'
      queriesLabel='Product, category or search URLs'
      queriesPlaceholder='https://www.samsclub.com/p/members-mark-facial-tissue-12-boxes/prod21292138&#10;https://www.samsclub.com/c/groceries-food/1444&#10;https://www.samsclub.com/s/furniture'
    />
  );
}
