import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Descriptions, Popconfirm, Button, Card, Table, Tooltip } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';
import Moment from 'react-moment';
import { isEmpty } from 'lodash';

import { stopSubscription, voidSubscription } from '@redux/actions/profileActions';
import { getLanguage } from '@utils/utils';

export default function UpcomingInvoice({ value = {}, loading = false, couponName = '', isSupport = false }) {
  const {
    subscription_id, total = 0, subtotal = 0, total_discount = 0, period_start, period_end, collection_method,
    products_lines = [], amount_due = 0, applied_balance = 0, reccuring
  } = value;
  const closable = total > 0;
  const locale = getLanguage();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const expandedRowRender = (item) => {
    const { lines = [] } = item;
    const columns = [
      {
        title: t('title.unitPrice'),
        dataIndex: 'unit_price',
        key: 'unit_price'
      },
      {
        title: t('title.quantity'),
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: t('title.amount'),
        dataIndex: 'amount',
        key: 'amount',
        render: (amount) => <>${amount}</>
      },
    ];
    return <Table size='small' columns={columns} dataSource={lines} pagination={false} />;
  };

  const columns = [
    {
      title: t('title.description'),
      dataIndex: 'product_name',
      key: 'description',
      render: (product_name) => <strong>{product_name}</strong>,
    },
    {
      title: t('title.unit'),
      dataIndex: 'unit',
      key: 'description',
      render: (unit) => unit ? t(`unit.${unit}`, unit) : '',
    },
    {
      title: t('title.quantity'),
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: t('title.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => <strong>{amount && `$${amount}`}</strong>,
    }
  ];

  return (
    <Card loading={loading} title={false} className='upcomingInvoiceCard'>
      <Descriptions
        bordered
        title={!isEmpty(value) && <><Moment format='MMM D' locale={locale} local unix>{period_start}</Moment> - <Moment format='MMM D' locale={locale} local unix>{period_end}</Moment></>}
        size='size'
        extra={
          <>
            {reccuring ? <>
              <Popconfirm
                title='Cancel subscription?'
                okText={t('action.ok')}
                cancelText={t('action.cancel')}
                onConfirm={() => dispatch(stopSubscription(subscription_id, true))}
              >
                <Button danger size='small' title={t('action.cancelSubscription')}>
                  <Trans i18nKey='action.cancelSubscription' />
                </Button>
              </Popconfirm>
            </> :
              <Popconfirm
                title='Stop the billing period and create an invoice?'
                okText={t('action.ok')}
                cancelText={t('action.cancel')}
                disabled={!closable}
                onConfirm={() => dispatch(stopSubscription(subscription_id))}
              >
                <Tooltip title={closable ? '' : t('description.generateInvoice')}>
                  <Button danger title={t('action.generateInvoice')} disabled={!closable}>
                    <Trans i18nKey='action.generateInvoice' />
                  </Button>
                </Tooltip>
              </Popconfirm>
            }
            {isSupport && subscription_id &&
              <Popconfirm title='Clear customer usage?' okText='Ok' cancelText='Cancel' onConfirm={() => dispatch(voidSubscription(subscription_id))}>
                <Button size='small' type='link' title='Clear Usage Period' icon={<CloseCircleOutlined />}>Clear</Button>
              </Popconfirm>
            }
          </>
        }
      >
        <Descriptions.Item label={t('title.nextInvoice')} span={1.5}>{period_end ? <Moment format='MMM D' locale={locale} local unix>{period_end}</Moment> : ''}</Descriptions.Item>
      </Descriptions>
      <Table
        size='small'
        columns={columns}
        expandable={{ expandedRowRender }}
        rowKey='product_name'
        dataSource={products_lines}
        pagination={false}
        scroll={{ x: '100%' }}
        summary={() => {
          return <>
            <Table.Summary.Row>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell><strong><Trans i18nKey='title.subtotal' /></strong></Table.Summary.Cell>
              <Table.Summary.Cell><strong>${subtotal}</strong></Table.Summary.Cell>
            </Table.Summary.Row>
            {couponName ?
              <Table.Summary.Row>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>{couponName}</Table.Summary.Cell>
                <Table.Summary.Cell><strong>- ${total_discount}</strong></Table.Summary.Cell>
              </Table.Summary.Row> : null
            }
            <Table.Summary.Row>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell><strong><Trans i18nKey='title.total' /></strong></Table.Summary.Cell>
              <Table.Summary.Cell><strong>${total}</strong></Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell><Trans i18nKey='title.appliedBalance' /></Table.Summary.Cell>
              <Table.Summary.Cell><strong>- ${applied_balance}</strong></Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell><strong><Trans i18nKey='title.amountDue' /></strong></Table.Summary.Cell>
              <Table.Summary.Cell><strong>${amount_due}</strong></Table.Summary.Cell>
            </Table.Summary.Row>
          </>;
        }}
      />
    </Card>
  );
}

UpcomingInvoice.propTypes = {
  value: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  couponName: PropTypes.string,
  isSupport: PropTypes.bool,
};
