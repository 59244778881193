import React from 'react';
import SimpleService from '@Common/SimpleService';

export default function WebstaurantstoreProducts() {
  return (
    <SimpleService
      unitName='product'
      title='Webstaurantstore Products Scraper'
      subTitle='Returns product listings'
      serviceName='webstaurantstore_products'
      learnMoreUrl='https://outscraper.com/webstaurantstore-scraper/'
      apiTag='Webstaurantstore/paths/~1webstaurantstore~1products/get'
      queriesLabel='Product, category or search URLs'
      queriesPlaceholder='https://www.webstaurantstore.com/choice-10-x-10-x-3-white-bundle/9.html&#10;https://www.webstaurantstore.com/47767/commercial-refrigerators.html&#10;https://www.webstaurantstore.com/search/chef-knife.html'
    />
  );
}
