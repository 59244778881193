import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Space } from 'antd';
import InfoTooltip from '@shared/Components/InfoTooltip';

const QueriesFormHeader = ({ title, tooltipTitle, children }) => (
  <Row className='w-100' justify='space-between' align='middle'>
    <Col className='alignCenter'>
      <Space align='center'>
        {title}
        {tooltipTitle && <InfoTooltip
          title={tooltipTitle}
        />}
      </Space>
    </Col>
    <Col>
      {children}
    </Col>
  </Row>
);

QueriesFormHeader.propTypes = {
  title: PropTypes.node.isRequired,
  tooltipTitle: PropTypes.node,
  children: PropTypes.node.isRequired
};

export default QueriesFormHeader;
