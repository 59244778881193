import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Space } from 'antd';
import { Trans } from 'react-i18next';

import { isPaidUser } from '@utils/utils';
import InfoTooltip from '@shared/Components/InfoTooltip';

function OrganizationsPerQueryInput({ value, onChange }) {
  const paidUser = isPaidUser();

  function handleChange(v) {
    onChange(parseInt(v) || 1);
  }

  return (
    <Space align='center' className='first-space-item-full'>
      <InputNumber
        className='w-100'
        disabled={!paidUser}
        min={1}
        max={500}
        step={20}
        value={value}
        onChange={handleChange}
      />
      <InfoTooltip title={<Trans i18nKey='description.organizationsPerQueryLimit'>
          Parameter specifies the limit of organizations to take from one query search.
        <br/><br/>
          When you are using the query to find a specific place (e.g. `Central Park NY US`) you probably want to use `1` to get the first place (just in case google will find something else). However, when you are searching for `restaurants, NY, USA` you might want to extract all the places from your search and use `500`.
        <br/><br/>
          There are no more than 500 places per one query search on Google Maps. For densely populated areas you might want to split your query into subqueries in order to get all the places inside. (e.g., `restaurants, Brooklyn 11211`, `restaurants, Brooklyn 11215`).
          To learn more about how to get all results please visit <a href='https://outscraper.com/google-maps-scrape-all-places/' target='_blank' rel='noopener noreferrer'>this article</a>.
      </Trans>}/>
    </Space>
  );
}

OrganizationsPerQueryInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(OrganizationsPerQueryInput, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
