import React from 'react';
import { Button, Modal, Form } from 'antd';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { servicesExtraItems } from '@Common/extraItems';
import { services } from '@shared/data/services';
import { useTranslation, Trans } from 'react-i18next';
import { isPaidUser } from '@utils/utils';

export const ParametersModal = ({ task, onChange, isOpen, onClose }) => {
  const { t } = useTranslation();
  const { enrichments, enrichments_kwargs = {} } = task;
  const paidUser = isPaidUser();
  const servicesExtraItemsKeys = new Set(Object.keys(servicesExtraItems));
  const enrichmentsWithExtraItems = isEmpty(enrichments) ? null : enrichments.filter((e) => servicesExtraItemsKeys.has(e));

  const handleSaveFilters = () => {
    onClose();
  };

  function updateEnrichmentKwargs(serviceName, params) {
    const newEnrichmentsKwargs = { ...enrichments_kwargs };
    newEnrichmentsKwargs[serviceName] = { ...enrichments_kwargs[serviceName], ...params };
    onChange({ enrichments_kwargs: newEnrichmentsKwargs });
  }

  const modalFooter = (
    <>
      <Button key='cancel' onClick={onClose}>
        <Trans i18nKey='action.cancel' />
      </Button>
      <Button disabled={!paidUser} type='primary' key='apply' onClick={handleSaveFilters}>
        <Trans i18nKey='title.save' />
      </Button>
    </>
  );

  return (
    <Modal centered width={424} open={isOpen} footer={modalFooter} closable={false}>
      <Form layout='vertical'>
        {enrichmentsWithExtraItems?.map(e => {
          const ExtraItems = servicesExtraItems[e];
          const serviceName = services[e].name || e;

          return <div key={e}>
            <h4 className='h4 mb-16'>{t(`title.${serviceName}`, serviceName)}</h4>
            <ExtraItems
              isModal
              updateTask={(params) => updateEnrichmentKwargs(e, params)}
              task={enrichments_kwargs[e] || {}}
              isPaidUser={paidUser}
            />
          </div>;
        })}
      </Form>
    </Modal>
  );
};

ParametersModal.propTypes = {
  task: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
