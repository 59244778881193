import React from 'react';
import { notification } from 'antd';

import Mixpanel from '@analytics/mixpanel';
import apiTasks from '@api/apiTasks';

export const START_REQUEST = 'START_REQUEST';
export const STOP_REQUEST = 'STOP_REQUEST';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_SCHEDULED_TASKS_SUCCESS = 'FETCH_SCHEDULED_TASKS_SUCCESS';

import { removeIntegration } from '@redux/actions/profileActions';

export const startRequest = () => ({ type: START_REQUEST });
export const stopRequest = () => ({ type: STOP_REQUEST });

export const fetchTasks = (lastId = '', pageSize = 10, query = '') => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiTasks.fetchTasks(lastId, pageSize, query);
    dispatch({ tasks: data.tasks, hasMoreTasks: data.has_more, type: FETCH_TASKS_SUCCESS });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const fetchScheduledTasks = () => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiTasks.fetchScheduledTasks();
    dispatch({ scheduledTasks: data.tasks, type: FETCH_SCHEDULED_TASKS_SUCCESS });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const rerunTask = (taskId) => async (dispatch, getState) => {
  dispatch(startRequest());
  try {
    const { data } = await apiTasks.rerunTask(taskId);
    notification.success({ message: data });

    const tasks = getState().tasksReducer.tasks;
    const hasMoreTasks = getState().tasksReducer.hasMoreTasks;

    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].id === taskId) {
        tasks[i].status = 'RESTARTED';
        break;
      }
    }

    dispatch({ tasks: [...tasks], hasMoreTasks, type: FETCH_TASKS_SUCCESS });
  } catch (error) {
    notification.error({ message: error.message, duration: 8 });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Rerun task');
};

export const rerunTasks = (taskIds) => async (dispatch, getState) => {
  dispatch(startRequest());
  try {
    const { data } = await apiTasks.rerunTasks(taskIds);
    notification.success({ message: data });

    const tasks = getState().tasksReducer.tasks;
    const hasMoreTasks = getState().tasksReducer.hasMoreTasks;

    for (let i = 0; i < tasks.length; i++) {
      if (taskIds.includes(tasks[i].id)) tasks[i].status = 'RESTARTED';
    }

    dispatch({ tasks: [...tasks], hasMoreTasks, type: FETCH_TASKS_SUCCESS });
  } catch (error) {
    notification.error({ message: error.message, duration: 8 });
  } finally {
    dispatch(stopRequest());
  }
};

export const removeTasks = (taskIds) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiTasks.removeTasks(taskIds);
    notification.success({ message: data });
    dispatch(fetchTasks());
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};

export const scheduleTask = (taskId, crontab, timezone = null) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiTasks.scheduleTask(taskId, crontab, timezone);
    notification.success({ message: data });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Schedule task');
};

export const removeScheduledTask = (taskId) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiTasks.removeScheduledTask(taskId);
    notification.success({ message: data });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Remove scheduled task');
};

export const pauseScheduledTask = (taskId) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiTasks.pauseScheduledTask(taskId);
    notification.success({ message: data });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Pause scheduled task');
};

export const resumeScheduledTask = (taskId) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiTasks.resumeScheduledTask(taskId);
    notification.success({ message: data });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }

  Mixpanel.track('Resume scheduled task');
};

export const exportToSpreadsheet = (taskIds, accessToken, refreshToken) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiTasks.exportToSpreadsheet(taskIds, accessToken, refreshToken);
    const { spreadsheet_urls } = data;
    return spreadsheet_urls;
  } catch (error) {
    if (error.message && error.message.includes('No refresh token found')) {
      notification.warning({
        message: 'Google Integration Error',
        description: (
          <>
            For security reasons, Google requires you to revoke access manually. Please visit&nbsp;
            <a href='https://myaccount.google.com/permissions' target='_blank' rel='noopener noreferrer'>
              Google Permissions
            </a> and remove Outscraper from the list. This will allow you to fully reset the integration.
          </>
        ),
        duration: 10
      });
      dispatch(removeIntegration('google', false));
    } else {
      notification.error({ message: error.message });
    }
  } finally {
    dispatch(stopRequest());
    Mixpanel.track('Export to spreadsheet');
  }
};
