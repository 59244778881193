import React from 'react';
import PropTypes from 'prop-types';
import { Attention, Error, Warning } from '@assets/icons';
import './CustomAlert.scss';

export const CustomAlert = ({ type, message }) => {
  const getIcon = () => {
    switch (type) {
    case 'warning':
      return <Warning />;
    case 'attention':
      return <Attention />;
    case 'error':
      return <Error />;
    default:
      return null;
    }
  };

  const icon = getIcon();
  const alertClassName = `custom-alert custom-alert--${type}`;

  return (
    <div className={alertClassName}>
      {icon}
      <div className='custom-alert__message'>
        {message}
      </div>
    </div>
  );
};

CustomAlert.propTypes = {
  type: PropTypes.oneOf(['warning', 'attention', 'danger']).isRequired,
  message: PropTypes.node.isRequired
};
