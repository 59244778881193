import React from 'react';

import SimpleService from '@Common/SimpleService';

export default function IndeedSearchScraper() {
  return (
    <SimpleService
      unitName='job'
      title='Indeed Job Scraper'
      subTitle='Returns search results'
      queriesLabel='Search URLs'
      serviceName='indeed_search'
      learnMoreUrl='https://outscraper.com/indeed-scraper/'
      queriesPlaceholder='https://www.indeed.com/jobs?q=&l=Fremont+Canyon%2C+CA'
      apiTag='Others/paths/~1indeed-search/get'
    />
  );
}
