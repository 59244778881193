import React from 'react';
import SimpleService from '@Common/SimpleService';

export default function CostcoProducts() {
  return (
    <SimpleService
      unitName='product'
      title='Costco Products Scraper'
      subTitle='Returns product listings'
      serviceName='costco_products'
      learnMoreUrl='https://outscraper.com/costco-scraper/'
      apiTag='Others/paths/~1costco~1products/get'
      queriesLabel='Product, category or search URLs'
      queriesPlaceholder='https://www.costco.com/Coca-Cola-24-pack-12-fl.-oz.-cans.product.100067101.html&#10;https://www.costco.com/organic-banana-3-lb.product.100067100.html&#10;https://www.costco.com/search?query=organic%20milk'
    />
  );
}
