import React, { useState } from 'react';
import { Drawer } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import config from 'react-global-configuration';

export default function PaymentInfo() {
  const [learnMore, setLearnMore] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <a onClick={() => setLearnMore(true)}><Trans i18nKey='action.whyConnectCard' /></a>
      <Drawer
        title={t('title.paymentsInfo', 'Payments Info')}
        placement='right'
        closable={false}
        onClose={() => setLearnMore(false)}
        open={learnMore}
      >
        <p>
          <Trans i18nKey='description.whyConnectCard1' /><Trans i18nKey='dot'>.</Trans><br/><br/>
          <Trans i18nKey='description.whyConnectCard2'>We will not charge you for the usage within <a href={`${config.get('pricingUrl')}#free`} target='_blank' rel='noopener noreferrer'>the free tier limits</a></Trans><Trans i18nKey='dot'>.</Trans>
        </p>
        <p>
          <Trans i18nKey='description.paymentsBackedBy'>
            Payments are backed by&nbsp;
            <a href='https://stripe.com' target='_blank' rel='noopener noreferrer'>Stripe</a>. It has the most stringent level of certification available in the payments industry (
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='http://www.visa.com/splisting/searchGrsp.do?companyNameCriteria=stripe'
            >PCI Service Provider Level 1</a>)
          </Trans><Trans i18nKey='dot'>.</Trans>&nbsp;
          <Trans i18nKey='description.paymentsRestAssured' /><Trans i18nKey='dot'>.</Trans>
        </p>
        <p>
          <Trans i18nKey='description.whyConnectCard4'>If you still want to try it without any obligation, <a href='mailto:support@outscraper.com?Subject=Free Credits For Testing' target='_top'>contact us</a> and let us know you better</Trans><Trans i18nKey='dot'>.</Trans>
        </p>
      </Drawer>
    </>
  );
}

export const warningMessage = <><Trans i18nKey='description.addCredits' />&#160;<PaymentInfo /></>;
export const testDriveMessage = <>
  <Trans i18nKey='description.testDrive'>Alternatively, you can learn about <a
    target='_blank'
    rel='noopener noreferrer'
    href='https://outscraper.com/test-drive/'
  >Outscraper 1-Year Free Test Drive Program</a></Trans></>;
