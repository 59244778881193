import React from 'react';

import SimpleService from '@Common/SimpleService';

export default function GooglePlacesUpdate() {
  return (
    <SimpleService
      unitName='place'
      title='Places Search Service'
      serviceName='places_search_service'
      queriesPlaceholder='{"city": "palo alto"}'
    />
  );
}
