import React from 'react';

import SimpleService from '@Common/SimpleService';

export default function HotelsReviewsScraper() {
  return (
    <SimpleService
      unitName='review'
      title='Hotels Reviews Scraper'
      startTaskButtonTitle='Export Reviews'
      subTitle='Returns reviews from a list of hotels'
      serviceName='hotels_reviews'
      queriesPlaceholder='https://www.hotels.com/ho1942679840/riu-plaza-manhattan-times-square-new-york-united-states-of-america'
      apiTag='Reviews-and-Comments/paths/~1hotels~1reviews/get'
      defaultParams={{ sort: 'NEWEST_TO_OLDEST_BY_LANGUAGE' }}
      recentCutoffSorting={'NEWEST_TO_OLDEST'}
      sortingOptions={[
        { value: 'NEWEST_TO_OLDEST_BY_LANGUAGE', text: 'Most relevant' },
        { value: 'NEWEST_TO_OLDEST', text: 'Most recent' },
        { value: 'HIGHEST_TO_LOWEST_RATED', text: 'Highest guest rating' },
        { value: 'LOWEST_TO_HIGHEST_RATED', text: 'Lowest guest rating' },
      ]}
    />
  );
}
