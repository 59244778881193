import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserSettings } from '@redux/actions/profileActions';
import { Layout, Menu, Space, Badge, Drawer, Modal, AutoComplete, Button } from 'antd';
import {
  StarOutlined,
  StarFilled,
  AppstoreOutlined,
  AmazonOutlined,
  AppleFilled,
  GoogleOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  EnvironmentOutlined,
  ShoppingOutlined,
  MailOutlined,
  PhoneOutlined,
  GlobalOutlined,
  ApiOutlined,
  ClockCircleOutlined,
  MenuOutlined,
  DollarOutlined,
  CreditCardOutlined,
  LogoutOutlined,
  UserOutlined,
  ScheduleOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
  ReadOutlined,
  VideoCameraOutlined,
  TeamOutlined,
  CloseCircleOutlined,
  CustomerServiceOutlined,
  BugOutlined,
  FileTextOutlined,
  HistoryOutlined,
  LinkOutlined,
  SearchOutlined,
  RedditOutlined,
  TikTokOutlined,
  ProductOutlined
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faChevronLeft, faLocationPin, faMagnet, faStar } from '@fortawesome/free-solid-svg-icons';
import { checkIfNewService, SERVICES_DATA } from '@shared/data/services';
import { isEmpty } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';
import config from 'react-global-configuration';
import { isCredibleUser } from '@utils/utils';
import { Logo, LogoMobile } from '@assets/icons';
import './Header.scss';
import FeatureRequestModal from '../Others/FeatureRequestModal';
import { useMedia } from '@hooks/useMedia';
import ThemeToggle from './ThemeToggle';
import Href from '@shared/Components/Href';

const { Header } = Layout;
const notRoutes = new Set(['support', 'report', 'logout']);

const HeaderComponent = ({ auth }) => {
  const navigate = useNavigate();
  const { isDesktop } = useMedia();
  const dispatch = useDispatch();

  const { profile = {}, authenticated = false } = auth;
  const { avatar, name, email, roles = [] } = profile;
  const isTenant = roles.includes('tenant');
  const isAdmin = roles.includes('admin');

  const { t, i18n } = useTranslation();

  const [featureRequestOpen, setFeatureRequestOpen] = useState(false);
  const [isHelpOpened, setIsHelpOpened] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');

  const { userSettings = { favorites: [] } } = useSelector(state => state.profileReducer);

  useEffect(() => {
    dispatch(fetchUserSettings());
  }, [dispatch]);

  const getParsedLocalStorage = (key, defaultValue = []) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  };

  const starredServicesArray = userSettings?.favorites || [];
  const recentServicesArray = getParsedLocalStorage('recentServices');
  const recentNewServicesArray = [
    ...getParsedLocalStorage('recentNewServices'),
    window.location.pathname.split('/')[1],
  ];

  function showUserName(email) {
    if (email) {
      return email.split('@')[0];
    } else {
      return email;
    }
  }

  function onHelpClick() {
    if (isHelpOpened) {
      window.Chatra('closeChat');
    } else {
      window.Chatra('updateIntegrationData', { email });
      window.Chatra('openChat', true);
    }
    setIsHelpOpened(!isHelpOpened);
  }

  function handleLogout() {
    Modal.confirm({
      title: t('title.logOut'),
      content: t('title.areYouSure'),
      okText: t('action.yes'),
      okType: 'danger',
      cancelText: t('action.no'),
      onOk() {
        navigate('/logout');
      },
    });
  }

  function getBadgeCount(key, children) {
    if (checkIfNewService(key, recentNewServicesArray)) return 1;

    let result = 0;
    if (Array.isArray(children)) {
      children.forEach(({ key, children }) => {
        result += getBadgeCount(key ? key.split('?')[0] : key, children);
      });
    }

    return result;
  }

  function getItem(label, key, icon, children, type) {
    let translatedLabel = (icon || children || type) ? label : t(`title.${label}`, label);

    const formatedKey = key ? key.split('?')[0] : key;
    const badgeCount = getBadgeCount(formatedKey, children);
    const isNewService = checkIfNewService(formatedKey, recentNewServicesArray);

    if (isEmpty(children)) {
      if (key.startsWith('https://') || key.startsWith('http://')) {
        translatedLabel = <a href={key} target='_blank' rel='noopener noreferrer'>{translatedLabel}</a>;
      } else if (!notRoutes.has(key)) {
        const ln = i18n.language.split('-')[0];
        if (ln !== 'en') {
          key = key + (key.includes('?') ? '&' : '?') + `ln=${ln}`;
        }

        translatedLabel = <Href href={key.startsWith('/') ? key : `/${key}`}>{translatedLabel}</Href>;
      }
    }

    if (badgeCount || isNewService) {
      translatedLabel = <Badge
        title={isNewService ? 'New service' : `${badgeCount} new servic(es)`}
        size='small'
        dot={isNewService || null}
        count={isNewService ? null : badgeCount}
        offset={[isNewService ? 6 : badgeCount > 9 ? 20 : 12, 0]}
        overflowCount={9}
      >
        <span>{translatedLabel}&nbsp;</span>&nbsp;
      </Badge>;
    }

    if (type === 'group' && icon) {
      translatedLabel = <><span style={{ marginRight: 8 }}>{icon}</span>{translatedLabel}&nbsp;</>;
    }

    return {
      key,
      icon,
      children,
      items: children,
      label: translatedLabel,
      type,
      title: translatedLabel
    };
  }

  const profileMenu = [
    getItem(authenticated ? name || showUserName(email) : t('title.profile'), 'profile-items', <Avatar
      className='profile-avatar'
      src={avatar}
      email={!avatar ? email : null}
      name={!avatar && !email ? name : showUserName(email)}
      size='32'
      round={true}
    />, [
      getItem(t('title.profile'), 'profile', <UserOutlined />),
      ...(isTenant ? [] : [
        getItem(t('title.billingInformation'), 'billing-info', <CreditCardOutlined />),
      ]),
      getItem(t('title.invoices'), 'invoices', <FileTextOutlined />),
      getItem(t('title.logOut'), 'logout', <LogoutOutlined />)
    ])];

  const servicesMenu = [
    getItem(t('title.byBrand'), 'sources', null, [
      getItem('Airbnb', 'source-airbnb', null, [
        getItem(SERVICES_DATA.airbnb_search_service.label, `${SERVICES_DATA.airbnb_search_service.key}?`),
        getItem(SERVICES_DATA.airbnb_reviews_service.label, `${SERVICES_DATA.airbnb_reviews_service.key}?`),
      ]),
      getItem('Amazon', 'source-amazon', <AmazonOutlined />, [
        getItem(SERVICES_DATA.amazon_products_service.label, `${SERVICES_DATA.amazon_products_service.key}?`),
        getItem(SERVICES_DATA.amazon_reviews_service.label, `${SERVICES_DATA.amazon_reviews_service.key}?`),
      ]),
      getItem('Apple', 'source-apple', <AppleFilled />, [
        getItem(SERVICES_DATA.appstore_reviews_service.label, `${SERVICES_DATA.appstore_reviews_service.key}?`),
      ]),
      getItem('BiggestBook', 'source-biggestbook', null, [
        getItem(SERVICES_DATA.biggestbook_products_service.label, `${SERVICES_DATA.biggestbook_products_service.key}?`),
      ]),
      getItem('Booking', 'source-booking', null, [
        getItem(SERVICES_DATA.booking_search_service.label, `${SERVICES_DATA.booking_search_service.key}?`),
        getItem(SERVICES_DATA.booking_reviews_service.label, `${SERVICES_DATA.booking_reviews_service.key}?`),
        getItem(SERVICES_DATA.booking_prices_service.label, `${SERVICES_DATA.booking_prices_service.key}?`),
      ]),
      getItem('Boots', 'source-boots', null, [
        getItem(SERVICES_DATA.boots_products_service.label, `${SERVICES_DATA.boots_products_service.key}?`),
      ]),
      getItem('BestBuy', 'source-boots', null, [
        getItem(SERVICES_DATA.bestbuy_reviews_service.label, `${SERVICES_DATA.bestbuy_reviews_service.key}?`),
      ]),
      getItem('Capterra', 'source-capterra', null, [
        getItem(SERVICES_DATA.capterra_reviews_service.label, `${SERVICES_DATA.capterra_reviews_service.key}?`),
      ]),
      getItem('Costco', 'source-costco', null, [
        getItem(SERVICES_DATA.costco_products_service.label, `${SERVICES_DATA.costco_products_service.key}?`),
      ]),
      getItem('eBay', 'source-ebay', null, [
        getItem(SERVICES_DATA.ebay_products_service.label, `${SERVICES_DATA.ebay_products_service.key}?`),
      ]),
      getItem('Expedia', 'source-expedia', null, [
        getItem(SERVICES_DATA.expedia_search_service.label, `${SERVICES_DATA.expedia_search_service.key}?`),
        getItem(SERVICES_DATA.expedia_reviews_service.label, `${SERVICES_DATA.expedia_reviews_service.key}?`),
      ]),
      getItem('Etsy', 'source-etsy', null, [
        getItem(SERVICES_DATA.etsy_reviews_service.label, `${SERVICES_DATA.etsy_reviews_service.key}?`),
        getItem(SERVICES_DATA.etsy_products_service.label, `${SERVICES_DATA.etsy_products_service.key}?`),
      ]),
      getItem('Fastbackgroundcheck', 'source-fastbackgroundcheck', null, [
        getItem(SERVICES_DATA.fastbackgroundcheck_addresses_service.label, `${SERVICES_DATA.fastbackgroundcheck_addresses_service.key}?`),
      ]),
      getItem('G2', 'source-g2', null, [
        getItem(SERVICES_DATA.g2_reviews_service.label, `${SERVICES_DATA.g2_reviews_service.key}?`),
      ]),
      getItem('GetApp', 'source-getapp', null, [
        getItem(SERVICES_DATA.getapp_reviews_service.label, `${SERVICES_DATA.getapp_reviews_service.key}?`),
      ]),
      getItem('Glassdoor', 'source-glassdoor', null, [
        getItem(SERVICES_DATA.glassdoor_search_service.label, `${SERVICES_DATA.glassdoor_search_service.key}?`),
        getItem(SERVICES_DATA.glassdoor_reviews_service.label, `${SERVICES_DATA.glassdoor_reviews_service.key}?`),
      ]),
      getItem('Google', 'source-google', <GoogleOutlined />, [
        getItem(SERVICES_DATA.google_maps_service.label, `${SERVICES_DATA.google_maps_service.key}?`),
        getItem(SERVICES_DATA.google_reviews_service.label, `${SERVICES_DATA.google_reviews_service.key}?`),
        getItem(SERVICES_DATA.google_search_service.label, `${SERVICES_DATA.google_search_service.key}?`),
        getItem(SERVICES_DATA.google_places_domain_search_service.label, `${SERVICES_DATA.google_places_domain_search_service.key}?`),
        getItem(SERVICES_DATA.google_maps_contributor_reviews_service.label, `${SERVICES_DATA.google_maps_contributor_reviews_service.key}?`),
        getItem(SERVICES_DATA.google_maps_photos_service.label, `${SERVICES_DATA.google_maps_photos_service.key}?`),
        getItem(SERVICES_DATA.google_traffic_service.label, `${SERVICES_DATA.google_traffic_service.key}?`),
        getItem(SERVICES_DATA.google_directory_places_service.label, `${SERVICES_DATA.google_directory_places_service.key}?`),
        getItem(SERVICES_DATA.google_search_news_service.label, `${SERVICES_DATA.google_search_news_service.key}?`),
        getItem(SERVICES_DATA.google_search_images_service.label, `${SERVICES_DATA.google_search_images_service.key}?`),
        getItem(SERVICES_DATA.google_search_videos_service.label, `${SERVICES_DATA.google_search_videos_service.key}?`),
        getItem(SERVICES_DATA.google_search_events_service.label, `${SERVICES_DATA.google_search_events_service.key}?`),
        getItem(SERVICES_DATA.google_search_jobs_service.label, `${SERVICES_DATA.google_search_jobs_service.key}?`),
        getItem(SERVICES_DATA.google_careers_service.label, `${SERVICES_DATA.google_careers_service.key}?`),
        getItem(SERVICES_DATA.google_search_shopping_service.label, `${SERVICES_DATA.google_search_shopping_service.key}?`),
      ]),
      getItem('Grainger', 'source-grainger', null, [
        getItem(SERVICES_DATA.grainger_products_service.label, `${SERVICES_DATA.grainger_products_service.key}?`),
      ]),
      getItem('Home Depot', 'source-homedepot', null, [
        getItem(SERVICES_DATA.homedepot_reviews_service.label, `${SERVICES_DATA.homedepot_reviews_service.key}?`),
      ]),
      getItem('Hotels', 'source-hotels', null, [
        getItem(SERVICES_DATA.hotels_search_service.label, `${SERVICES_DATA.hotels_search_service.key}?`),
        getItem(SERVICES_DATA.hotels_reviews_service.label, `${SERVICES_DATA.hotels_reviews_service.key}?`),
      ]),
      getItem('Indeed', 'source-indeed', null, [
        getItem(SERVICES_DATA.indeed_search_service.label, `${SERVICES_DATA.indeed_search_service.key}?`),
        getItem(SERVICES_DATA.indeed_reviews_service.label, `${SERVICES_DATA.indeed_reviews_service.key}?`),
      ]),
      getItem('Macys', 'source-macys', null, [
        getItem(SERVICES_DATA.macys_products_service.label, `${SERVICES_DATA.macys_products_service.key}?`),
      ]),
      getItem('Office Depot', 'source-officedepot', null, [
        getItem(SERVICES_DATA.officedepot_products_service.label, `${SERVICES_DATA.officedepot_products_service.key}?`),
      ]),
      getItem('Webstaurantstore', 'source-webstaurantstore', null, [
        getItem(SERVICES_DATA.webstaurantstore_products_service.label, `${SERVICES_DATA.webstaurantstore_products_service.key}?`),
      ]),
      getItem('Linkedin', 'source-Linkedin', <LinkedinOutlined />, [
        getItem(SERVICES_DATA.linkedin_companies_service.label, `${SERVICES_DATA.linkedin_companies_service.key}?`),
        getItem(SERVICES_DATA.linkedin_profiles_service.label, `${SERVICES_DATA.linkedin_profiles_service.key}?`),
        getItem(SERVICES_DATA.linkedin_posts_service.label, `${SERVICES_DATA.linkedin_posts_service.key}?`),
      ]),
      getItem('Macys', 'source-macys', null, [
        getItem(SERVICES_DATA.macys_products_service.label, `${SERVICES_DATA.macys_products_service.key}?`),
      ]),
      getItem('Product Hunt', 'source-producthunt', null, [
        getItem(SERVICES_DATA.producthunt_reviews_service.label, `${SERVICES_DATA.producthunt_reviews_service.key}?`),
      ]),
      getItem('Reddit', 'source-reddit', <RedditOutlined />, [
        getItem(SERVICES_DATA.reddit_search_service.label, `${SERVICES_DATA.reddit_search_service.key}?`),
        getItem(SERVICES_DATA.reddit_comments_service.label, `${SERVICES_DATA.reddit_comments_service.key}?`),
      ]),
      getItem('Sams Club', 'source-samsclub', null, [
        getItem(SERVICES_DATA.samsclub_products_service.label, `${SERVICES_DATA.samsclub_products_service.key}?`),
      ]),
      getItem('Staples', 'source-staples', null, [
        getItem(SERVICES_DATA.staples_products_service.label, `${SERVICES_DATA.staples_products_service.key}?`),
      ]),
      getItem('Shopee', 'source-shopee', null, [
        getItem(SERVICES_DATA.shopee_reviews_service.label, `${SERVICES_DATA.shopee_reviews_service.key}?`),
      ]),
      getItem('Shopify', 'source-shopify', null, [
        getItem(SERVICES_DATA.shopify_products_service.label, `${SERVICES_DATA.shopify_products_service.key}?`),
      ]),
      getItem('Target', 'source-target', null, [
        getItem(SERVICES_DATA.target_reviews_service.label, `${SERVICES_DATA.target_reviews_service.key}?`),
      ]),
      getItem('Tesco', 'source-tesco', null, [
        getItem(SERVICES_DATA.tesco_products_service.label, `${SERVICES_DATA.tesco_products_service.key}?`),
      ]),
      getItem('TikTok', 'source-tiktok', <TikTokOutlined />, [
        getItem(SERVICES_DATA.tiktok_profiles_service.label, `${SERVICES_DATA.tiktok_profiles_service.key}?`),
      ]),
      getItem('Tripadvisor', 'source-tripadvisor', <FontAwesomeIcon icon={faBuilding} />, [
        getItem(SERVICES_DATA.tripadvisor_search_service.label, `${SERVICES_DATA.tripadvisor_search_service.key}?`),
        getItem(SERVICES_DATA.tripadvisor_reviews_service.label, `${SERVICES_DATA.tripadvisor_reviews_service.key}?`),
      ]),
      getItem('Trustpilot', 'source-trustpilot', <FontAwesomeIcon icon={faStar} />, [
        getItem(SERVICES_DATA.trustpilot_service.label, `${SERVICES_DATA.trustpilot_service.key}?`),
        getItem(SERVICES_DATA.trustpilot_search_service.label, `${SERVICES_DATA.trustpilot_search_service.key}?`),
        getItem(SERVICES_DATA.trustpilot_reviews_service.label, `${SERVICES_DATA.trustpilot_reviews_service.key}?`),
      ]),
      getItem('Twitter', 'source-twitter', <TwitterOutlined />, [
        getItem(SERVICES_DATA.twitter_profiles_service.label, `${SERVICES_DATA.twitter_profiles_service.key}?`),
      ]),
      getItem('Walmart', 'source-walmart', null, [
        getItem(SERVICES_DATA.walmart_reviews_service.label, `${SERVICES_DATA.walmart_reviews_service.key}?`),
      ]),
      getItem('Whitepages', 'source-whitepages', null, [
        getItem(SERVICES_DATA.whitepages_phones_service.label, `${SERVICES_DATA.whitepages_phones_service.key}?`),
        getItem(SERVICES_DATA.whitepages_addresses_service.label, `${SERVICES_DATA.whitepages_addresses_service.key}?`),
      ]),
      getItem('Yellow Pages', 'yellow-pages', null, [
        getItem(SERVICES_DATA.yellowpages_service.label, `${SERVICES_DATA.yellowpages_service.key}?`),
      ]),
      getItem('Y.E.L.P', 'source-yelp', <FontAwesomeIcon icon={faBuilding} />, [
        getItem(SERVICES_DATA.yelp_service.label, `${SERVICES_DATA.yelp_service.key}?`),
        getItem(SERVICES_DATA.yelp_reviews_service.label, `${SERVICES_DATA.yelp_reviews_service.key}?`),
        getItem(SERVICES_DATA.yelp_photos_service.label, `${SERVICES_DATA.yelp_photos_service.key}?`),
      ]),
      getItem('Youtube', 'source-youtube', <YoutubeOutlined />, [
        getItem(SERVICES_DATA.youtube_channels_service.label, `${SERVICES_DATA.youtube_channels_service.key}?`),
        getItem(SERVICES_DATA.youtube_comments_service.label, `${SERVICES_DATA.youtube_comments_service.key}?`),
        getItem(SERVICES_DATA.youtube_search_service.label, `${SERVICES_DATA.youtube_search_service.key}?`),
      ]),
      getItem('Zillow', 'source-zillow', <FontAwesomeIcon icon={faBuilding} />, [
        getItem(SERVICES_DATA.zillow_search_service.label, `${SERVICES_DATA.zillow_search_service.key}?`),
      ]),
    ]),
    getItem(t('title.byType'), 'types', null, [
      getItem(t('title.poiRelated'), 'pois', <EnvironmentOutlined />, [
        getItem(SERVICES_DATA.google_maps_service.label, SERVICES_DATA.google_maps_service.key),
        getItem(SERVICES_DATA.google_places_domain_search_service.label, SERVICES_DATA.google_places_domain_search_service.key),
        getItem(SERVICES_DATA.tripadvisor_search_service.label, SERVICES_DATA.tripadvisor_search_service.key),
        getItem(SERVICES_DATA.trustpilot_search_service.label, SERVICES_DATA.trustpilot_search_service.key),
        getItem(SERVICES_DATA.trustpilot_service.label, SERVICES_DATA.trustpilot_service.key),
        getItem(SERVICES_DATA.linkedin_companies_service.label, SERVICES_DATA.linkedin_companies_service.key),
        getItem(SERVICES_DATA.whitepages_addresses_service.label, SERVICES_DATA.whitepages_addresses_service.key),
        getItem(SERVICES_DATA.fastbackgroundcheck_addresses_service.label, SERVICES_DATA.fastbackgroundcheck_addresses_service.key),
        getItem(SERVICES_DATA.yellowpages_service.label, SERVICES_DATA.yellowpages_service.key),
        getItem(SERVICES_DATA.yelp_service.label, SERVICES_DATA.yelp_service.key),
        getItem(SERVICES_DATA.company_insights_service.label, SERVICES_DATA.company_insights_service.key),
        getItem(SERVICES_DATA.similarweb_service.label, SERVICES_DATA.similarweb_service.key),
        getItem(SERVICES_DATA.business_directory_service.label, SERVICES_DATA.business_directory_service.key),
        getItem('Hotels', 'hotels', null, [
          getItem(SERVICES_DATA.airbnb_search_service.label, SERVICES_DATA.airbnb_search_service.key),
          getItem(SERVICES_DATA.booking_search_service.label, SERVICES_DATA.booking_search_service.key),
          getItem(SERVICES_DATA.booking_prices_service.label, SERVICES_DATA.booking_prices_service.key),
          getItem(SERVICES_DATA.expedia_search_service.label, SERVICES_DATA.expedia_search_service.key),
          getItem(SERVICES_DATA.hotels_search_service.label, SERVICES_DATA.hotels_search_service.key),
        ]),
      ]),
      getItem(t('title.reviewsRelated'), 'reviews', <StarOutlined />, [
        getItem(SERVICES_DATA.amazon_reviews_service.label, SERVICES_DATA.amazon_reviews_service.key),
        getItem(SERVICES_DATA.appstore_reviews_service.label, SERVICES_DATA.appstore_reviews_service.key),
        getItem(SERVICES_DATA.airbnb_reviews_service.label, SERVICES_DATA.airbnb_reviews_service.key),
        getItem(SERVICES_DATA.booking_reviews_service.label, SERVICES_DATA.booking_reviews_service.key),
        getItem(SERVICES_DATA.bestbuy_reviews_service.label, SERVICES_DATA.bestbuy_reviews_service.key),
        getItem(SERVICES_DATA.capterra_reviews_service.label, SERVICES_DATA.capterra_reviews_service.key),
        getItem(SERVICES_DATA.expedia_reviews_service.label, SERVICES_DATA.expedia_reviews_service.key),
        getItem(SERVICES_DATA.g2_reviews_service.label, SERVICES_DATA.g2_reviews_service.key),
        getItem(SERVICES_DATA.getapp_reviews_service.label, SERVICES_DATA.getapp_reviews_service.key),
        getItem(SERVICES_DATA.glassdoor_reviews_service.label, SERVICES_DATA.glassdoor_reviews_service.key),
        getItem(SERVICES_DATA.google_reviews_service.label, SERVICES_DATA.google_reviews_service.key),
        getItem(SERVICES_DATA.google_maps_contributor_reviews_service.label, SERVICES_DATA.google_maps_contributor_reviews_service.key),
        getItem(SERVICES_DATA.google_play_reviews_service.label, SERVICES_DATA.google_play_reviews_service.key),
        getItem(SERVICES_DATA.google_shopping_reviews_service.label, SERVICES_DATA.google_shopping_reviews_service.key),
        getItem(SERVICES_DATA.homedepot_reviews_service.label, SERVICES_DATA.homedepot_reviews_service.key),
        getItem(SERVICES_DATA.hotels_reviews_service.label, SERVICES_DATA.hotels_reviews_service.key),
        getItem(SERVICES_DATA.etsy_reviews_service.label, SERVICES_DATA.etsy_reviews_service.key),
        getItem(SERVICES_DATA.indeed_reviews_service.label, SERVICES_DATA.indeed_reviews_service.key),
        getItem(SERVICES_DATA.producthunt_reviews_service.label, SERVICES_DATA.producthunt_reviews_service.key),
        getItem(SERVICES_DATA.reddit_comments_service.label, SERVICES_DATA.reddit_comments_service.key),
        getItem(SERVICES_DATA.shopee_reviews_service.label, SERVICES_DATA.shopee_reviews_service.key),
        getItem(SERVICES_DATA.target_reviews_service.label, SERVICES_DATA.target_reviews_service.key),
        getItem(SERVICES_DATA.tripadvisor_reviews_service.label, SERVICES_DATA.tripadvisor_reviews_service.key),
        getItem(SERVICES_DATA.trustpilot_reviews_service.label, SERVICES_DATA.trustpilot_reviews_service.key),
        getItem(SERVICES_DATA.walmart_reviews_service.label, SERVICES_DATA.walmart_reviews_service.key),
        getItem(SERVICES_DATA.youtube_comments_service.label, SERVICES_DATA.youtube_comments_service.key),
        getItem(SERVICES_DATA.yelp_reviews_service.label, SERVICES_DATA.yelp_reviews_service.key),
      ]),
      getItem(t('title.productsRelated'), 'products', <ShoppingOutlined />, [
        getItem(SERVICES_DATA.amazon_products_service.label, SERVICES_DATA.amazon_products_service.key),
        getItem(SERVICES_DATA.biggestbook_products_service.label, SERVICES_DATA.biggestbook_products_service.key),
        getItem(SERVICES_DATA.boots_products_service.label, SERVICES_DATA.boots_products_service.key),
        getItem(SERVICES_DATA.ebay_products_service.label, SERVICES_DATA.ebay_products_service.key),
        getItem(SERVICES_DATA.macys_products_service.label, SERVICES_DATA.macys_products_service.key),
        getItem(SERVICES_DATA.staples_products_service.label, SERVICES_DATA.staples_products_service.key),
        getItem(SERVICES_DATA.officedepot_products_service.label, SERVICES_DATA.officedepot_products_service.key),
        getItem(SERVICES_DATA.samsclub_products_service.label, SERVICES_DATA.samsclub_products_service.key),
        getItem(SERVICES_DATA.costco_products_service.label, SERVICES_DATA.costco_products_service.key),
        getItem(SERVICES_DATA.webstaurantstore_products_service.label, SERVICES_DATA.webstaurantstore_products_service.key),
        getItem(SERVICES_DATA.google_search_shopping_service.label, SERVICES_DATA.google_search_shopping_service.key),
        getItem(SERVICES_DATA.shopify_products_service.label, SERVICES_DATA.shopify_products_service.key),
        getItem(SERVICES_DATA.etsy_products_service.label, SERVICES_DATA.etsy_products_service.key),
        getItem(SERVICES_DATA.tesco_products_service.label, SERVICES_DATA.tesco_products_service.key),
        getItem(SERVICES_DATA.grainger_products_service.label, SERVICES_DATA.grainger_products_service.key),
      ]),
      getItem(t('title.emailRelated'), 'emails', <MailOutlined />, [
        getItem(SERVICES_DATA.emails_scraper_service.label, SERVICES_DATA.emails_scraper_service.key),
        getItem(SERVICES_DATA.contacts_finder_service.label, SERVICES_DATA.contacts_finder_service.key),
        getItem(t('action.check'), null, null, [
          getItem(SERVICES_DATA.emails_validator_service.label, SERVICES_DATA.emails_validator_service.key),
          getItem(SERVICES_DATA.disposable_email_checker_service.label, SERVICES_DATA.disposable_email_checker_service.key),
        ], 'group'),
        getItem(SERVICES_DATA.email_finder_service.label, SERVICES_DATA.email_finder_service.key),
      ]),
      getItem(t('title.phoneRelated'), 'phones', <PhoneOutlined />, [
        getItem(SERVICES_DATA.phones_enricher_service.label, SERVICES_DATA.phones_enricher_service.key),
        getItem(SERVICES_DATA.whitepages_phones_service.label, SERVICES_DATA.whitepages_phones_service.key),
        // getItem(t('action.check'), null, null, [
        // getItem(SERVICES_DATA.whatsapp_checker_service.label, SERVICES_DATA.whatsapp_checker_service.key),
        // getItem('iMessage Checker', 'imessage-checker'),
        // getItem('Telegram Checker', 'telegram-checker'),
        // getItem('WeChat Checker', 'wechat-checker'),
        // ], 'group'),
        // getItem(t('action.send', 'Send'), null, null, [
        //   getItem('WhatsApp Sender', 'whatsapp-sender'),
        //   getItem('iMessage Sender', 'imessage-sender'),
        // ], 'group'),
      ]),
      getItem(t('title.domainRelated'), 'domains', <GlobalOutlined />, [
        getItem(SERVICES_DATA.emails_scraper_service.label, `${SERVICES_DATA.emails_scraper_service.key}?tab=domain`),
        getItem(SERVICES_DATA.contacts_finder_service.label, `${SERVICES_DATA.contacts_finder_service.key}?tab=domain`),
        getItem(SERVICES_DATA.company_insights_service.label, `${SERVICES_DATA.company_insights_service.key}?tab=domain`),
        getItem(SERVICES_DATA.similarweb_service.label, `${SERVICES_DATA.similarweb_service.key}?tab=domain`),
        getItem(SERVICES_DATA.google_places_domain_search_service.label, `${SERVICES_DATA.google_places_domain_search_service.key}?tab=domain`),
        getItem(SERVICES_DATA.trustpilot_service.label, `${SERVICES_DATA.trustpilot_service.key}?type=domain`),
      ]),
    ]),
    getItem(t('title.byUseCase'), 'usecases', null, [
      getItem(t('title.leadGeneration'), 'usecases-lead', <FontAwesomeIcon icon={faMagnet} />, [
        getItem(SERVICES_DATA.google_maps_service.label, `${SERVICES_DATA.google_maps_service.key}?tab=lead`),
        getItem(SERVICES_DATA.contacts_finder_service.label, `${SERVICES_DATA.contacts_finder_service.key}?tab=lead`),
        getItem(SERVICES_DATA.emails_scraper_service.label, `${SERVICES_DATA.emails_scraper_service.key}?tab=lead`),
        getItem(SERVICES_DATA.email_finder_service.label, `${SERVICES_DATA.email_finder_service.key}?tab=lead`),
        getItem(SERVICES_DATA.emails_validator_service.label, `${SERVICES_DATA.emails_validator_service.key}?tab=lead`),
        getItem(SERVICES_DATA.disposable_email_checker_service.label, `${SERVICES_DATA.disposable_email_checker_service.key}?tab=lead`),
      ]),
      getItem(t('title.reputationManagement'), 'usecases-rep', <StarFilled />, [
        getItem(SERVICES_DATA.amazon_reviews_service.label, `${SERVICES_DATA.amazon_reviews_service.key}?tab=rep`),
        getItem(SERVICES_DATA.appstore_reviews_service.label, `${SERVICES_DATA.appstore_reviews_service.key}?tab=rep`),
        getItem(SERVICES_DATA.booking_reviews_service.label, `${SERVICES_DATA.booking_reviews_service.key}?tab=rep`),
        getItem(SERVICES_DATA.capterra_reviews_service.label, `${SERVICES_DATA.capterra_reviews_service.key}?tab=rep`),
        getItem(SERVICES_DATA.google_reviews_service.label, `${SERVICES_DATA.google_reviews_service.key}?tab=rep`),
        getItem(SERVICES_DATA.google_maps_contributor_reviews_service.label, `${SERVICES_DATA.google_maps_contributor_reviews_service.key}?tab=rep`),
        getItem(SERVICES_DATA.google_play_reviews_service.label, `${SERVICES_DATA.google_play_reviews_service.key}?tab=rep`),
        getItem(SERVICES_DATA.google_shopping_reviews_service.label, `${SERVICES_DATA.google_shopping_reviews_service.key}?tab=rep`),
        getItem(SERVICES_DATA.hotels_reviews_service.label, `${SERVICES_DATA.hotels_reviews_service.key}?tab=rep`),
        getItem(SERVICES_DATA.producthunt_reviews_service.label, `${SERVICES_DATA.producthunt_reviews_service.key}?tab=rep`),
        getItem(SERVICES_DATA.shopee_reviews_service.label, `${SERVICES_DATA.shopee_reviews_service.key}?tab=rep`),
        getItem(SERVICES_DATA.tripadvisor_reviews_service.label, `${SERVICES_DATA.tripadvisor_reviews_service.key}?tab=rep`),
        getItem(SERVICES_DATA.trustpilot_reviews_service.label, `${SERVICES_DATA.trustpilot_reviews_service.key}?tab=rep`),
        getItem(SERVICES_DATA.youtube_comments_service.label, `${SERVICES_DATA.youtube_comments_service.key}?tab=rep`),
        getItem(SERVICES_DATA.yelp_reviews_service.label, `${SERVICES_DATA.yelp_reviews_service.key}?tab=rep`),
      ]),
      getItem(t('title.locationIntelligence'), 'usecases-intel', <FontAwesomeIcon icon={faLocationPin} />, [
        getItem(SERVICES_DATA.google_maps_service.label, `${SERVICES_DATA.google_maps_service.key}?tab=intel`),
        getItem(SERVICES_DATA.google_places_domain_search_service.label, `${SERVICES_DATA.google_places_domain_search_service.key}?tab=intel`),
        getItem(SERVICES_DATA.yelp_service.label, `${SERVICES_DATA.yelp_service.key}?tab=intel`),
        getItem(SERVICES_DATA.yellowpages_service.label, `${SERVICES_DATA.yellowpages_service.key}?tab=intel`),
      ]),
    ]),
    getItem(t('title.otherServices'), 'others', null, [
      getItem(SERVICES_DATA.universal_scraper_service.label, SERVICES_DATA.universal_scraper_service.key),
      getItem(SERVICES_DATA.screenshoter_service.label, SERVICES_DATA.screenshoter_service.key),
      getItem('HubSpot', 'hubspot-group', null, [
        getItem(SERVICES_DATA.hubspot_contact_emails_finder_service.label, SERVICES_DATA.hubspot_contact_emails_finder_service.key),
        getItem(SERVICES_DATA.hubspot_company_contacts_finder_service.label, SERVICES_DATA.hubspot_company_contacts_finder_service.key),
      ], 'group'),
      getItem('Geocoding', 'geocoding-group', null, [
        getItem(SERVICES_DATA.reverse_geocoding_service.label, SERVICES_DATA.reverse_geocoding_service.key),
        getItem(SERVICES_DATA.geocoding_service.label, SERVICES_DATA.geocoding_service.key),
      ], 'group'),
    ]),
    ...(isAdmin ? [
      getItem('Internal Services', 'internal', null, [
        getItem('Places DB Search', 'places-search'),
        getItem('Places DB Update', 'places-update'),
        getItem('Domain Contacts Finder', 'domain-contacts-finder'),
      ])
    ] : []),
  ];
  const favoritesAndRecentServices = [
    !isEmpty(starredServicesArray) && getItem(t('title.favorites'), 'services.favorites', <StarOutlined />,
      [...starredServicesArray.map((service) => {
        const { title, href } = service;
        return getItem(title, href);
      }),
      ]),
    !isEmpty(recentServicesArray) && getItem(t('title.recentlyVisited'), 'recently-visited', <ClockCircleOutlined />, [
      ...recentServicesArray.map((service) => {
        const { title, href } = service;
        return getItem(title, href + '?tab=recent');
      }),
    ]),
  ];
  const apiMenu = [
    getItem(t('title.apiDocs'), 'api-docs', <FileTextOutlined />),
    getItem(t('title.apiUsage'), 'api-usage', <HistoryOutlined />),
    getItem(t('title.integrations'), 'integrations', <LinkOutlined />),
  ];
  const helpMenu = [
    getItem('F.A.Q.', config.get('faqUrl'), <QuestionOutlined />),
    getItem(t('title.tutorials'), config.get('tutorialsUrl'), <ReadOutlined />),
    getItem(t('title.videos'), config.get('videosUrl'), <VideoCameraOutlined />),
    getItem(t('title.askCommunity'), config.get('communityUrl'), <TeamOutlined />),
    getItem(t('title.support'), 'support', isHelpOpened ? <CloseCircleOutlined /> : <CustomerServiceOutlined />),
    ...(isCredibleUser() ? [getItem(t('title.schedule'), 'https://outscraper.com/support/#schedule', <PhoneOutlined />)] : []),
    getItem(t('title.reportProblem'), 'report', <BugOutlined />),
    getItem(t('title.pricing'), config.get('pricingUrl'), <DollarOutlined />),
  ];
  const rootMenu = [
    getItem(t('title.services'), 'services', <AppstoreOutlined />, servicesMenu),
    getItem(t('title.tasks'), 'tasks', <ScheduleOutlined />),
    getItem('API' + ' & ' + t('title.integrations'), 'api-integrations', <ApiOutlined />, apiMenu),
    getItem(t('title.help'), 'help', <QuestionCircleOutlined />, helpMenu)
  ];

  const [menuStack, setMenuStack] = useState([getItem('Main Menu', 'root', null, rootMenu)]);
  const currentMenu = menuStack[menuStack.length - 1];

  const handleSearch = useCallback((value) => {
    setSearchTerm(value);
  }, []);

  const translateServicesData = (data) => {
    return data.map(item => {
      const translatedLabel = t(`title.${item.label}`) || item.label;
      return {
        ...item,
        label: translatedLabel,
        children: item.children ? translateServicesData(item.children) : undefined,
      };
    });
  };

  const filteredItems = useMemo(() => {
    const translatedData = translateServicesData(Object.values(SERVICES_DATA));
    return translatedData.filter((value) => value.label.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [searchTerm]);

  const handleHeaderMenuClick = (menuKey) => {
    const selectedMenu = rootMenu.find((item) => item.key === menuKey);
    if (selectedMenu?.items?.length > 0) {
      setMenuStack([getItem('Main Menu', 'root', null, rootMenu), selectedMenu]);
      setDrawerOpen(true);
    } else {
      setDrawerOpen(false);
    }
  };

  const goBack = () => {
    if (searchTerm) return;
    setMenuStack((prev) => {
      if (prev.length > 1) {
        return prev.slice(0, -1);
      } else {
        return [getItem('Main Menu', 'root', null, rootMenu)];
      }
    });
  };

  const handleMenuClick = ({ key }) => {
    const selectedItem = currentMenu.items.find((item) => item.key === key);

    if (selectedItem?.items?.length > 0) {
      setMenuStack((prev) => [...prev, selectedItem]);
    } else {
      if (key.startsWith('https://') || key.startsWith('http://')) {
        window.open(key, '_blank', 'noopener,noreferrer');
      } else if (key === 'support') {
        onHelpClick();
      } else if (key === 'report') {
        setFeatureRequestOpen((prev) => !prev);
      } else if (key === 'logout') {
        handleLogout();
      } else {
        navigate(key);
      }
      setSearchTerm('');
      setDrawerOpen(false);
    }
  };

  const drawerTitle = menuStack.length > 1 ?
    <Space align='center' className='drawer-title' onClick={goBack}>
      <span className={`back-icon ${searchTerm ? 'disabled' : ''}`}> <FontAwesomeIcon icon={faChevronLeft} /> </span>
      <h4 className='h4 bold'>{currentMenu.label}</h4>
    </Space> : <h4 className='h4 bold'>Menu</h4>;

  const accountMenu = () =>
    <Menu className='account-menu' theme={isDesktop ? 'dark' : 'light'} mode={isDesktop ? 'horizontal' : 'inline'} items={profileMenu} onClick={handleMenuClick} />;

  return (
    <Header theme='light' className='header'>
      <Link to='/' className='logo'>
        {isDesktop ? <Logo /> : <LogoMobile />}
      </Link>
      {isDesktop && (
        <Space size={32} className='menu'>
          {rootMenu.map((item) => (
            item.children ? (
              <span className='menu-item' key={item.key} onClick={() => handleHeaderMenuClick(item.key)}>
                {item.key === 'services' && <ProductOutlined className='mr-8' />}
                {item.title}
              </span>)
              : <span key={item.key}>{item.title}</span>
          ))}
        </Space>
      )}
      <Space className='right-controls' >
        <Space className='theme-switcher' size={0}>
          {isDesktop && (<ThemeToggle />)}
        </Space>
        {!isDesktop && (
          <Button type='text' className='burger-menu' onClick={() => setDrawerOpen(true)}>
            <MenuOutlined />
          </Button>
        )}
      </Space>
      <Drawer
        title={drawerTitle}
        placement={isDesktop ? 'left' : 'right'}
        onClose={() => setDrawerOpen(false)}
        open={isDrawerOpen}
      >
        <Space direction='vertical' className='w-100'>
          {!isDesktop && (currentMenu.key === 'root') && accountMenu()}
          {currentMenu.key === 'services' &&
            <AutoComplete
              variant='borderless'
              className='w-100'
              onChange={handleSearch}
              options={filteredItems.map(item => ({ value: item.label, key: item.key }))}
              placeholder={t('title.search')}
              onSelect={(value, option) => handleMenuClick({ key: option.key })}
              prefix={<SearchOutlined />}
              value={searchTerm}
              allowClear
              onClear={() => setSearchTerm('')}
            />
          }
          <Menu
            className='main-menu'
            mode='inline'
            openKeys={[]}
            items={(currentMenu.items).map((item) => ({
              key: item.key,
              icon: item.icon,
              label: (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!searchTerm) {
                      handleMenuClick({ key: item.key });
                    }
                  }}
                >
                  {item.label}
                </div>
              ),
              children: item.children,
              disabled: searchTerm,
            }))}
          />
          {currentMenu.key === 'services' && <Menu
            className='favorites-recent-menu'
            defaultOpenKeys={isEmpty(starredServicesArray) ? ['recently-visited'] : ['services.favorites']}
            mode='inline'
            items={favoritesAndRecentServices}
            onClick={handleMenuClick}
          />}
          {!isDesktop && currentMenu.key === 'root' && <ThemeToggle />}
        </Space>
      </Drawer>
      {isDesktop && accountMenu()}
      <FeatureRequestModal bug open={featureRequestOpen} onChange={setFeatureRequestOpen} />
    </Header>
  );
};

HeaderComponent.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default HeaderComponent;
