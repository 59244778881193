import React from 'react';

import { services, defaultWebsiteEnrichments, defaultPhoneEnrichments } from '@shared/data/services';
import SimpleService from '@Common/SimpleService';

export default function TrustpilotSearchScraper() {
  return (
    <SimpleService
      unitName='biz'
      title={services.trustpilot_search_service.name}
      subTitle={services.trustpilot_search_service.description}
      serviceName='trustpilot_search_service'
      queriesPlaceholder='real estate&#10;marketing consultant'
      learnMoreUrl='https://outscraper.com/trustpilot-scraper'
      apiTag='Trustpilot/paths/~1trustpilot~1search/get'
      enrichmentFields={services.trustpilot_search_service.input_fields}
      supportedEnrichmentServices={[]}
      excludedEnrichmentServices={['trustpilot_service']}
      defaultEnrichments={[ ...defaultWebsiteEnrichments, ...defaultPhoneEnrichments ]}
    />
  );
}
