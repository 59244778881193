import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Button, Form, Result, Divider, notification, Space } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { isEmpty } from 'lodash';

import HCaptcha from '@hcaptcha/react-hcaptcha';
import config from 'react-global-configuration';
import { GoogleLogin } from '@react-oauth/google';
import { LinkedinIcon } from '@assets/icons';

const { Item } = Form;

const hcaptchaSiteKey = config.get('hcaptchaSiteKey');
const linkedinClientId = config.get('linkedinClientId');

export default function LoginSignupForm({ children, location, onFinish, title, subTitle, AfterButtonComponent, loading, checkEmail, captcha = false, skipLogin = true, onGoogleLogin = null, onLinkedinLogin, submitButtonTitle = null }) {
  const { search } = location;
  const { redirect, email, utm_source } = queryString.parse(search);
  const [cachePayload, setCachePayload] = useState(null);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const { t } = useTranslation();
  const hcaptchaRef = useRef(null);

  if (redirect) localStorage.setItem('redirect', redirect);
  if (utm_source) localStorage.setItem('source', utm_source);

  const { linkedInLogin } = useLinkedIn({
    clientId: linkedinClientId,
    redirectUri: `${window.location.origin}/linkedin-callback`,
    scope: 'openid profile email',
    onSuccess: (code) => onLinkedinLogin(code),
    onError: (error) => console.error('Error:', error),
  });

  useEffect(() => {
    document.title = subTitle;

    if (skipLogin && localStorage.getItem('isLoggedIn') === '1') {
      window.location.replace(redirect || '/');
    }
  }, []);

  function toLogin() {
    window.location.replace('/login');
  }

  function handleOnFinish(payload) {
    if (captcha) {
      if (isEmpty(cachePayload)) {
        setCachePayload(payload);
        setShowCaptcha(true);
        hcaptchaRef.current.execute();
      } else {
        onFinish({ ...cachePayload, ...payload });
      }
    } else {
      onFinish(payload);
    }
  }

  function handleCaptchaVerify(value) {
    onFinish({ ...cachePayload, 'captcha': value });
    clearCachePayload();
  }

  function clearCachePayload() {
    setCachePayload(null);
    setShowCaptcha(false);
  }

  function onGoogleLoginSuccess({ credential }) {
    if (onGoogleLogin) onGoogleLogin(credential);
  }

  function onGoogleLoginFailure() {
    notification.error({ message: 'Failed' });
  }

  return <div className='loginSignupForm'>
    {checkEmail ?
      <Result
        status='success'
        title={t('title.checkInbox', 'Check Your Inbox')}
        subTitle={<><Trans i18nKey='description.checkEmail'>We&apos;ve sent you an email to</Trans> <strong>{checkEmail}</strong></>}
        extra={[
          <Button block type='primary' key='login' onClick={toLogin}>
            <Trans i18nKey='title.logIn'>Log In</Trans>
          </Button>,
        ]}
      />
      : <Space direction='vertical' align='center' size={16} className='space-items-full'>
        <Space direction='vertical' align='center'>
          <img src='https://outscraper.com/files/logo-cube-inverted.png' alt='Outscraper Logo' width='100' />
          <h1>{title}</h1>
          <p>{subTitle}</p>
        </Space>
        <Form name='login' className='login-form' initialValues={{ remember: true, email }} onFinish={handleOnFinish}>
          <Space direction='vertical' size={16} className='w-100'>
            {children}
            <Item>
              <Space direction='vertical' className='w-100'>
                <Button block type='primary' htmlType='submit' loading={loading} disabled={showCaptcha}>
                  {submitButtonTitle ? submitButtonTitle : <Trans i18nKey='title.continue'>Continue</Trans>}
                </Button>
                {onGoogleLogin && <Space direction='vertical' className='space-items-full'>
                  <Divider plain><Trans i18nKey='title.or'>or</Trans></Divider>
                  <GoogleLogin
                    onSuccess={onGoogleLoginSuccess}
                    onError={onGoogleLoginFailure}
                    text='continue_with'
                    width='350'
                  />
                </Space>}
                <Button className='loginWithBtn' onClick={linkedInLogin} icon={<LinkedinIcon />}>
                  <Trans i18nKey='action.continueWith'/>
                  LinkedIn
                </Button>
              </Space>
            </Item>
          </Space>
          <HCaptcha
            size='invisible'
            ref={hcaptchaRef}
            sitekey={hcaptchaSiteKey}
            onVerify={handleCaptchaVerify}
            onExpire={clearCachePayload}
            onClose={clearCachePayload}
          />
        </Form>
        {AfterButtonComponent && <AfterButtonComponent />}
      </Space>
    }
  </div>;
}

LoginSignupForm.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  AfterButtonComponent: PropTypes.func,
  loading: PropTypes.bool,
  checkEmail: PropTypes.string,
  captcha: PropTypes.bool,
  skipLogin: PropTypes.bool,
  onGoogleLogin: PropTypes.func,
  onLinkedinLogin: PropTypes.func,
  submitButtonTitle: PropTypes.string,
};
