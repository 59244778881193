import React from 'react';
import { Avatar, Carousel, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { testimonials } from '@shared/data/testimonials';

const { Title, Paragraph } = Typography;

const Testimonials = () =>
  <Carousel autoplay autoplaySpeed={6000}>
    {testimonials.map((testimonial) => (
      <div style={{ textAlign: 'center' }} key={testimonial.name}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a href={testimonial.link} target='_blank' rel='noopener noreferrer'>
            <Avatar
              size={64}
              icon={<UserOutlined />}
              src={testimonial.avatar}
              style={{ marginBottom: '12px', marginTop: '4px' }}
            />
          </a>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Title level={4} style={{ marginBottom: 0, display: 'inline-block' }}>{testimonial.name}</Title>
          <a href={testimonial.link} target='_blank' rel='noopener noreferrer' style={{ marginLeft: '8px' }}>{testimonial.icon}</a>
          <Paragraph italic style={{ marginTop: '4px' }}>{testimonial.position}</Paragraph>
        </div>
        <Paragraph italic style={{ marginTop: '12px', marginBottom: '32px', marginLeft: '4px' }}>{testimonial.testimonial}</Paragraph>
      </div>
    ))}
  </Carousel>;

export default Testimonials;
