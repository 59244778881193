import React from 'react';
import SimpleService from '@Common/SimpleService';

export default function BiggestBookProducts() {
  return (
    <SimpleService
      unitName='product'
      title='BiggestBook Products Scraper'
      subTitle='Returns product listings'
      serviceName='biggestbook_products'
      learnMoreUrl='https://outscraper.com/biggestbook-scraper/'
      apiTag='BiggestBook/paths/~1biggestbook~1products/get'
      queriesLabel='Product, category or search URLs'
      queriesPlaceholder='https://www.biggestbook.com/product/12345&#10;https://www.biggestbook.com/category/books&#10;https://www.biggestbook.com/search?q=bestsellers'
    />
  );
}
