import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Col, DatePicker, Form, InputNumber, Space, Select, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import { services } from '@shared/data/services';
import BaseService from './BaseService';
import CustomQueries from './Selects/CustomQueries';
import EnrichmentsItem from '../Common/EnrichmentsItem';
import ReviewsSortSelect from './Selects/ReviewsSortSelect';
import InfoTooltip from '@shared/Components/InfoTooltip';
import PremiumFeatureTooltip from '@shared/Components/PremiumFeatureTooltip';
import { validateDefault } from '@utils/validation';

const { Text } = Typography;
const { Option } = Select;
const { Item } = Form;

const FORMAT = 'YYYY/MM/DD HH:mm:ss';

export default function SimpleService({ title, subTitle, serviceName, queriesLabel = 'Queries', queriesPlaceholder = '',
  unitName = 'item', limitType = 'per_query', defaultLimit = 100, maxLimit, sortingOptions, learnMoreUrl, videoTutorialLink, tutorialLink, apiTag,
  startTaskButtonTitle, fields, enrichmentFields, beta = false, defaultParams = {}, supportedEnrichmentServices, excludedEnrichmentServices,
  ExtraItems, QueriesFormItems, allowFileUpload = true, validateTask = validateDefault, showParams = true, requiredIntegration, tourSteps, recentCutoffSorting,
  serviceOntologies, defaultEnrichments = [],
}) {
  const { t } = useTranslation();
  const localeQueriesLabel = queriesLabel ? t(`title.${queriesLabel}`, queriesLabel) : '';
  const localEnrichmentsKey = `${serviceName}_enrichments`;
  const isTotalLimitType = limitType === 'total';

  function getServiceFields() {
    const serviceData = services[serviceName];
    if (isEmpty(serviceData) || isEmpty(serviceData.output_fields)) return null;
    return ['query'].concat(serviceData.output_fields);
  }

  return <>
    <BaseService
      title={title}
      subTitle={subTitle}
      serviceName={serviceName}
      unitName={unitName}
      beta={beta}
      fields={fields ? fields : getServiceFields()}
      startTaskButtonTitle={startTaskButtonTitle}
      learnMoreUrl={learnMoreUrl}
      videoTutorialLink={videoTutorialLink}
      tutorialLink={tutorialLink}
      apiTag={apiTag}
      tourSteps={tourSteps}
      validateTask={validateTask}
      requiredIntegration={requiredIntegration}
      taskExtraDefaultParams={{
        limit: isTotalLimitType ? defaultLimit : 0,
        limit_per_query: limitType ? defaultLimit : 1,
        enrichments: localStorage.getItem(localEnrichmentsKey) ? JSON.parse(localStorage.getItem(localEnrichmentsKey)) : defaultEnrichments,
        ...defaultParams
      }}
      showParams={showParams}
      FormBody={({ updateTask, task, invalidFields }) => {
        const { queries, limit, limit_per_query, input_file: inputFile, settings } = task;

        function onFileUploaded(input_file, enrich, output_extension) {
          updateTask({ input_file, enrich, queries: '', settings: { ...settings, output_extension } });
        }

        const labelConfig = isTotalLimitType
          ? {
            label: t('title.totalLimit'),
            tooltip: t('description.totalLimit'),
          }
          : {
            label: t('title.perQueryLimit'),
            tooltip: t('description.perQueryLimit'),
          };

        return <Space size={24} direction='vertical' className='w-100'>
          {showParams && (
            QueriesFormItems ? <QueriesFormItems task={task} updateTask={updateTask} invalidFields={invalidFields} /> : <Col xs={24} lg={16} xl={12}>
              <Item required label={localeQueriesLabel} className='queries-input'>
                <CustomQueries
                  value={queries}
                  onChange={(v) => updateTask({ queries: v })}
                  inputFile={inputFile}
                  onFileUploaded={allowFileUpload ? onFileUploaded : null}
                  placeholder={queriesPlaceholder}
                  enrichmentFields={enrichmentFields}
                  error={invalidFields.includes('queries')}
                />
              </Item>
            </Col>
          )}

          {limitType && <Col xs={24}>
            <Item
              label={
                <Space>
                  {labelConfig.label}
                  <InfoTooltip title={labelConfig.tooltip} />
                </Space>
              }
              className='formItem limit'
              wrapperCol={{
                xs: { span: 24 },
                lg: { span: 6 },
                xl: { span: 4 },
              }}
            >
              <Space align='center' className='first-space-item-full'>
                {isTotalLimitType ? (
                  <InputNumber
                    className='w-100'
                    min={1}
                    step={100}
                    value={limit}
                    max={maxLimit}
                    onChange={(v) => updateTask({ limit: parseInt(v) || 1 })}
                  />
                ) : (
                  <InputNumber
                    className='w-100'
                    min={1}
                    step={100}
                    value={limit_per_query}
                    max={maxLimit}
                    onChange={(v) => updateTask({ limit_per_query: parseInt(v) || 1 })}
                  />
                )}
              </Space>
            </Item>
          </Col>}
        </Space>;
      }}
      FormBodyExtra={({ updateTask, task, isPaidUser, invalidFields }) => {
        const { sort, cutoff, relativeCutoff } = task;
        const useCutoffDate = !!cutoff;

        const relativeCutoffRanges = {
          '1d': 24 * 60 * 60,
          '1w': 7 * 24 * 60 * 60,
          '1m': 30 * 24 * 60 * 60,
          '3m': 90 * 24 * 60 * 60,
          '1y': 365 * 24 * 60 * 60,
        };

        function onEnrichmentChange(enrichments) {
          localStorage.setItem(localEnrichmentsKey, JSON.stringify(enrichments));
          updateTask({ enrichments });
        }

        function onSetCutoffDate(value) {
          updateTask({ cutoff: value });
        }

        function onSetUseCutoffDate() {
          updateTask({ cutoff: cutoff ? null : dayjs().startOf('month') });
        }

        function onSetUseRelativeCutoffDate() {
          updateTask({
            relativeCutoff: relativeCutoff ? null : relativeCutoffRanges['1d'],
            cutoff: relativeCutoff ? cutoff : dayjs().subtract(1, 'day')
          });
        }

        function onSetRelativeCutoffRange(range) {
          const seconds = relativeCutoffRanges[range];
          updateTask({
            relativeCutoff: seconds,
            cutoff: dayjs().subtract(seconds, 'second')
          });
        }

        function disabledCutoffDate(current) {
          return current > dayjs();
        }

        function onSetSorting(value) {
          updateTask({ sort: value });
        }

        const sortingLabelWithTooltip = <Space>
          {t('title.sorting')}
          <InfoTooltip title={t('description.sorting')} />
        </Space>;

        if (!ExtraItems && !supportedEnrichmentServices && !sortingOptions) {
          return;
        }

        return <Space className='w-100' size={16} direction='vertical'>
          {ExtraItems && <ExtraItems task={task} updateTask={updateTask} isPaidUser={isPaidUser} invalidFields={invalidFields} />}

          {supportedEnrichmentServices &&
            <>
              <EnrichmentsItem
                task={task}
                updateTask={updateTask}
                onChange={onEnrichmentChange}
                supportedEnrichmentServices={supportedEnrichmentServices}
                excludedEnrichmentServices={excludedEnrichmentServices}
                serviceOntologies={serviceOntologies}
                title={title}
                learnMoreUrl={learnMoreUrl}
              />
            </>
          }
          {sortingOptions && <Space className='w-100' size={16} direction='vertical'>
            <Col xs={24} lg={6} xl={4}>
              <Item label={sortingLabelWithTooltip}>
                <ReviewsSortSelect onChange={onSetSorting} value={sort} options={sortingOptions} />
              </Item>
            </Col>
            {recentCutoffSorting !== undefined && recentCutoffSorting === sort && <Col xs={24} lg={6} xl={4}>
              <Item>
                <Space direction='vertical' className='w-100'>
                  <PremiumFeatureTooltip isPaidUser={isPaidUser}>
                    <Checkbox disabled={!isPaidUser} checked={useCutoffDate} onChange={() => onSetUseCutoffDate()}><Trans i18nKey='action.gmr.newestFrom' /></Checkbox>
                  </PremiumFeatureTooltip>
                  {useCutoffDate && <Space align='center' style={{ flexWrap: 'nowrap' }}>
                    <Item className='w-180px' >
                      {relativeCutoff ?
                        <Space>
                          <Select
                            value={Object.entries(relativeCutoffRanges).find(([_, value]) => value === relativeCutoff)?.[0] || '1d'}
                            onChange={onSetRelativeCutoffRange}
                            className='w-100px'
                          >
                            <Option value='1d'>1 day</Option>
                            <Option value='1w'>1 week</Option>
                            <Option value='1m'>1 month</Option>
                            <Option value='3m'>3 months</Option>
                            <Option value='1y'>1 year</Option>
                          </Select>
                          <Text strong>
                            <Moment unix local fromNow>{dayjs().unix() - relativeCutoff}</Moment>
                          </Text>
                        </Space>
                        :
                        <DatePicker
                          showTime
                          format={FORMAT}
                          value={cutoff}
                          onChange={onSetCutoffDate}
                          disabledDate={disabledCutoffDate}
                          className='w-180px'
                        />
                      }
                    </Item>
                    <Checkbox
                      checked={!!relativeCutoff}
                      onChange={() => onSetUseRelativeCutoffDate()}
                    >
                      <Trans i18nKey='title.relative' />
                    </Checkbox>
                  </Space>}
                </Space>
              </Item>
            </Col>}
          </Space>}
        </Space>;
      }}
    />
  </>;
}

SimpleService.propTypes = {
  title: PropTypes.string,
  serviceName: PropTypes.string,
  queriesLabel: PropTypes.string,
  queriesPlaceholder: PropTypes.string,
  limitType: PropTypes.string,
  sortingOptions: PropTypes.array,
  recentCutoffSorting: PropTypes.string,
  defaultLimit: PropTypes.number,
  maxLimit: PropTypes.number,
  defaultParams: PropTypes.object,
  unitName: PropTypes.string,
  subTitle: PropTypes.string,
  learnMoreUrl: PropTypes.string,
  videoTutorialLink: PropTypes.string,
  apiTag: PropTypes.string,
  tutorialLink: PropTypes.string,
  startTaskButtonTitle: PropTypes.string,
  fields: PropTypes.array,
  enrichmentFields: PropTypes.array,
  supportedEnrichmentServices: PropTypes.array,
  excludedEnrichmentServices: PropTypes.array,
  serviceOntologies: PropTypes.array,
  beta: PropTypes.bool,
  ExtraItems: PropTypes.func,
  QueriesFormItems: PropTypes.func,
  allowFileUpload: PropTypes.bool,
  validateTask: PropTypes.func,
  showParams: PropTypes.bool,
  requiredIntegration: PropTypes.string,
  tourSteps: PropTypes.array,
  defaultEnrichments: PropTypes.array,
};
