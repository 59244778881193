import React from 'react';
import SimpleService from '@Common/SimpleService';

export default function BootsProducts() {
  return (
    <SimpleService
      unitName='product'
      title='Boots Products Scraper'
      subTitle='Returns product listings'
      serviceName='boots_products'
      learnMoreUrl='https://outscraper.com/boots-scraper/'
      apiTag='Boots/paths/~1boots~1products/get'
      queriesLabel='Product, category or search URLs'
      queriesPlaceholder = 'https://www.boots.com/health-and-pharmacy&#10;https://www.boots.com/health-and-pharmacy/medical-devices&#10;https://www.boots.com/health-and-pharmacy/medical-devices/blood-pressure-monitors'
    />);
}
