import React from 'react';
import { Tooltip, Badge, Space } from 'antd';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import { showConfirm } from './ReasonsToAddCredits';
import { Crown } from '@assets/icons';

export default function PremiumFeatureTooltip({ isPaidUser, children, hideIcon=false }) {
  function onShowReasonsToAddCredits() {
    if (!isPaidUser) showConfirm(true);
  }

  return <Tooltip className='w-100' onClick={onShowReasonsToAddCredits} title={isPaidUser ? '' : (
    <Space direction='vertical'>
      <Space align='center' direction='horizontal'>
        <a onClick={onShowReasonsToAddCredits}>
          <Trans i18nKey='action.premiumFeature' />
          <Crown style={{ marginLeft: '4px' }} />
        </a>
      </Space>
      <Trans i18nKey='description.whyAddCredits3' />
    </Space>
  )}>
    <Badge className='premium-badge' count={isPaidUser || hideIcon ? null : <>&nbsp;<Crown />&nbsp;</>}>
      {children}
    </Badge>
  </Tooltip>;
}

PremiumFeatureTooltip.propTypes = {
  isPaidUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  children: PropTypes.node.isRequired,
  hideIcon: PropTypes.bool,
};
