import React from 'react';

import SimpleService from '@Common/SimpleService';

export default function EtsyReviewsScraper() {
  return (
    <SimpleService
      unitName='review'
      title='Etsy Reviews Scraper'
      startTaskButtonTitle='Export Reviews'
      subTitle='Returns reviews from a list of Etsy products'
      serviceName='etsy_reviews'
      queriesPlaceholder='https://www.etsy.com/listing/12345/reviews&#10;https://www.etsy.com/shop/ExampleShop/reviews&#10;https://www.etsy.com/search?q=handmade+bags'
      apiTag='Etsy/paths/~1etsy-reviews/get'
      defaultParams={{ sort: 'Relevancy' }}
      recentCutoffSorting={'Recency'}
      sortingOptions={[
        { value: 'Recency', text: 'Most Recent' },
        { value: 'Highest', text: 'Highest Rating' },
        { value: 'Lowest', text: 'Lowest Rating' },
      ]}
    />
  );
}
