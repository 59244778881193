import React, { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { Form, Input, Button, Card, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import config from 'react-global-configuration';

import apiClient from '@api/apiClient';
import Results from './Results';

const { Item } = Form;

const hcaptchaSiteKey = config.get('hcaptchaSiteKey');
const domainPattern = new RegExp('^(https?:\\/\\/)?[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\\.[a-zA-Z]{2,})+', 'i');

export default function DomainContacts() {
  const captchaRef = useRef(null);

  const [token, setToken] = useState(undefined);
  const [domain, setDomain] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState({});
  const [usage, setUsage] = useState(0);

  const showResults = !loading && !isEmpty(results);

  useEffect(() => {
    document.title = 'Domain Contacts Lookup';
  }, []);

  function resetCaptcha() {
    setToken(undefined);
    if (captchaRef.current) {
      captchaRef.current.resetCaptcha();
    }
  }

  async function onVerify(token) {
    await search(domain, token);
    setToken(token);
    setDomain(undefined);
    setUsage(2);
  }

  async function onSearch({ domain }) {
    if (token && usage >= 1) {
      setUsage(usage - 1);
      await search(domain, token);
    } else {
      setToken(null);
      setDomain(domain);
    }
  }

  async function search(domain, token) {
    setLoading(true);
    try {
      let parts = domain.split('//');
      let formatedDomain = parts[parts.length - 1];
      parts = formatedDomain.split('wwww.');
      formatedDomain = parts[parts.length - 1].split('/')[0];

      const { data } = await apiClient.post(`/domain-contacts-lookup/${formatedDomain}`, { token });
      delete data.query;

      if (isEmpty(data)) {
        message.warning(`Domain ${domain} not found`);
        setResults({});
      } else {
        setResults(data);
      }

    } catch (error) {
      message.error(error.message);
      resetCaptcha();
    }
    setLoading(false);
  }

  return (
    <Card style={{ width: 800, margin: 'auto' }}>
      <Form layout='inline' onFinish={onSearch}>
        <Item name='domain' rules={[
          { required: true, message: 'Please enter a domain to search', validateTrigger: 'onSubmit' },
          { type: 'string', pattern: domainPattern, message: 'Please enter a valid domain', validateTrigger: 'onSubmit' },
        ]}>
          <Input style={{ width: 200 }} placeholder='company.com' autoFocus />
        </Item>
        <Item>
          <Button
            type='primary'
            htmlType='submit'
            loading={loading}
            icon={<SearchOutlined />}
          >
            Find Contacts
          </Button>
        </Item>
      </Form>
      {token === null &&
        <div style={{ marginTop: 15 }}>
          <HCaptcha
            ref={captchaRef}
            sitekey={hcaptchaSiteKey}
            onVerify={onVerify}
            onExpire={resetCaptcha}
          />
        </div>
      }
      {showResults && <Results value={results} />}
    </Card>
  );
}
