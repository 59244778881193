import React from 'react';

import SimpleService from '@Common/SimpleService';

export default function HotelsSearchScraper() {
  return (
    <SimpleService
      unitName='record'
      title='Hotels Search Scraper'
      subTitle='Returns search results'
      queriesLabel='Search URLs'
      serviceName='hotels_search'
      learnMoreUrl='https://outscraper.com/hotels-scraper/'
      queriesPlaceholder='https://www.hotels.com/Hotel-Search?destination=Toronto%2C%20Ontario%2C%20Canada&regionId=4089&sort=RECOMMENDED'
      apiTag='Hotels'
    />
  );
}
