import React, { useState, useEffect } from 'react';
import { Button, Space, Switch } from 'antd';
import { MoonOutlined, SunOutlined } from '@ant-design/icons';
import { useTheme } from '@context/ThemeContext';
import './Header.scss';
import { useMedia } from '@hooks/useMedia';

const ThemeToggle = () => {
  const { isMobileOrTable } = useMedia();
  const { isDarkMode, toggleTheme } = useTheme();
  const [animating, setAnimating] = useState(false);
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    if (animating) {
      const timer = setTimeout(() => {
        setAnimating(false);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [animating]);

  const handleToggleTheme = () => {
    const effects = ['pulse', 'color-transition', 'shake'];
    const randomEffect = effects[Math.floor(Math.random() * effects.length)];

    setAnimationClass(randomEffect);
    setAnimating(true);
    toggleTheme();
  };

  return (
    <>
      {isMobileOrTable ? (
        <Space className='first-space-item-full theme-toggle-wrapper'>
          <Space>
            {isDarkMode ? <SunOutlined /> : <MoonOutlined />}
            <h4 className='h4'>Dark Theme</h4>
          </Space>
          <Switch checked={isDarkMode} size='small' onClick={handleToggleTheme} />
        </Space>
      ) : (
        <Button
          type='text'
          icon={isDarkMode ? <SunOutlined /> : <MoonOutlined />}
          onClick={handleToggleTheme}
        />
      )}
    </>
  );
};

export default ThemeToggle;
