import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Drawer, InputNumber, App } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import apiServices from '@api/apiServices';
import PricingCards from './PricingCards';

const PriceLabel = styled.span`
  color: #C41230;
  font-size: 18px;
`;

const PriceDetailLabel = styled.span`
  font-size: 14px;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 150px;
  margin-left: 5px;
`;

export default function Pricing({ service, unitName = 'item', calculatorDefaultUsage = 500 }) {
  const [opened, setOpened] = useState(false);
  const [prices, setPrices] = useState([]);
  const [usage, setUsage] = useState(calculatorDefaultUsage);
  const [usagePrice, setUsagePrice] = useState(0);
  const { t } = useTranslation();
  const { notification } = App.useApp();

  useEffect(() => {
    if (service) {
      fetchPrices(service);
    }
  }, [service]);

  async function fetchPrices(serviceName) {
    try {
      const { data } = await apiServices.getServicePrices(serviceName);

      setPrices(data);
      onChangeUsage(data[0].up_to, data);
    } catch (error) {
      notification.error({ message: error.message });
    }
  }

  function onChangeUsage(usage, forwardefPrices = prices) {
    setUsage(usage);
    onCalculatePrice(usage, forwardefPrices);
  }

  function onCalculatePrice(usage, prices) {
    setUsagePrice(calculatePrice(usage, prices));
  }

  function calculatePrice(usage, prices) {
    let price = 0;
    let previousUpTo = 0;

    for (let i = 0; i < prices.length; i++) {
      const { up_to, unit_amount_decimal } = prices[i];

      if (up_to && usage > up_to) {
        price += (up_to - previousUpTo) * unit_amount_decimal;
        previousUpTo = up_to;
      } else {
        return price + (usage - previousUpTo) * unit_amount_decimal;
      }
    }

    return price;
  }

  return (
    <>
      <a onClick={() => setOpened(true)}><Trans i18nKey='title.pricing'>Pricing</Trans></a>
      <Drawer
        title={t('title.pricing', 'Pricing')}
        placement='right'
        closable={false}
        onClose={() => setOpened(false)}
        open={opened}
      >
        <PricingCards value={prices} unitName={t(`unit.${unitName}`, unitName)} />
        <br/><br/>
        <h4><Trans i18nKey='title.calculatePrice' /></h4>
        <Trans i18nKey='title.quantity' />: <StyledInputNumber min={0} max={10000000} value={usage} step={1000} onChange={onChangeUsage} /><br/><br/>
        <Trans i18nKey='title.calculatedPrice' />: <PriceDetailLabel>$<PriceLabel>{usagePrice.toFixed(2)}</PriceLabel></PriceDetailLabel>
        <br/><br/>
        <p className='help-p1'>
          * <Trans i18nKey='description.calculatedPrice' /> <a target='_blank' rel='noopener noreferrer' href='https://outscraper.com/pricing/'><Trans i18nKey='action.learnMore' />.</a>
          <br/>
        </p>
        {/* <Trans i18nKey='title.calculatedPrice'>e</Trans>: <PriceDetailLabel>$<PriceLabel>{usagePrice.toFixed(2)}</PriceLabel></PriceDetailLabel> */}
      </Drawer>
    </>
  );
}

Pricing.propTypes = {
  service: PropTypes.string.isRequired,
  unitName: PropTypes.string,
  calculatorDefaultUsage: PropTypes.number,
};
