import React from 'react';

import { services } from '@shared/data/services';
import SimpleService from '@Common/SimpleService';

export default function LinkedinCompaniesScraper() {
  return (
    <SimpleService
      limitType={null}
      unitName={services.linkedin_companies_service.unit}
      queriesLabel='URLs to company profiles, company IDs'
      title={services.linkedin_companies_service.name}
      subTitle={services.linkedin_companies_service.description}
      serviceName='linkedin_companies_service'
      queriesPlaceholder='outscraper&#10;https://www.linkedin.com/company/outscraper/'
      learnMoreUrl='https://outscraper.com/linkedin-companies-scraper'
      enrichmentFields={services.linkedin_companies_service.input_fields}
      supportedEnrichmentServices={[]}
      apiTag='Others/paths/~1linkedin~1companies/gett'
    />
  );
}
