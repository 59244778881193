import React from 'react';
import { notification, Modal, Space, Button, List, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { GoogleSheets } from '@assets/icons';
import { exportToSpreadsheet } from '@redux/actions/tasksActions';
import { dynamicallyCreateAnchorAndNavigate } from '@utils/helpers/openInNewTab';

const { Text } = Typography;

export async function openSpreadsheet(taskIds, dispatch) {
  try {
    const spreadsheetUrls = await dispatch(exportToSpreadsheet(taskIds, null, null));

    if (spreadsheetUrls.length === 1) {
      notification.success({
        message: <a href={spreadsheetUrls[0]} target='_blank' rel='noopener noreferrer'>Open on Google Sheets</a>,
        duration: 30, style: { width: 600 }
      });
      dynamicallyCreateAnchorAndNavigate(spreadsheetUrls[0]);
    } else if (spreadsheetUrls.length > 1) {
      Modal.success({
        title: 'Files Exported Successfully',
        content: (
          <Space direction='vertical' size='middle'>
            <Text className='h4 mb-16'>Your files have been exported to Google Sheets:</Text>
            <List
              className='w-100 mb-24'
              size='large'
              dataSource={spreadsheetUrls}
              renderItem={(url, index) => (
                <List.Item className='alignCenter'>
                  <Space size={12} align='center'>
                    <GoogleSheets />
                    <a href={url} target='_blank' rel='noopener noreferrer'>
                      Spreadsheet {index + 1}
                    </a>
                  </Space>
                </List.Item>
              )}
            />
            <Space className='justifyCenter'>
              <Button
                className='btn'
                type='primary'
                icon={<DownloadOutlined />}
                onClick={() => spreadsheetUrls.forEach(url => dynamicallyCreateAnchorAndNavigate(url))}
              >
                Open All Spreadsheets
              </Button>
            </Space>
          </Space>
        ),
        width: 550,
        footer: (
          <Space className='justifyFlexEnd'>
            <Button key='close' onClick={() => Modal.destroyAll()}>
              Close
            </Button>
          </Space>
        ),
      });
    } else {
      notification.error({
        message: 'Failed to upload file',
        description: 'Something went wrong while exporting the spreadsheet. Please try again later.',
        duration: 10
      });
    }
  } catch (error) {
    notification.error({
      message: 'Error exporting file',
      description: 'An unexpected error occurred. Please check your connection or try again.',
      duration: 10
    });
  }
}
