import React from 'react';
import { Col, Form, InputNumber, Space } from 'antd';

import BaseService from '@Common/BaseService';
import CustomQueries from '@Common/Selects/CustomQueries';
import GoogleLocationSelect from '@Common/Google/LocationSelect';
import InfoTooltip from '@shared/Components/InfoTooltip';

const { Item } = Form;

export default function GoogleProductsReviews() {
  const taskExtraDefaultParams = {
    region: 'US',
    google_domain: 'google.com',
    language: 'en',

    limit_per_query: 100,
    skip: 0,
  };

  return (
    <BaseService
      title='Google Shopping Reviews Scraper'
      subTitle='Returns reviews from a list of products'
      serviceName='google_products_reviews_service'
      startTaskButtonTitle='Export Reviews'
      unitName='review'
      learnMoreUrl='https://outscraper.com/google-shopping-reviews-scraper'
      apiTag='Google/paths/~1google-shopping-reviews/get'
      taskExtraDefaultParams={taskExtraDefaultParams}
      FormBody={({ task, updateTask, invalidFields }) => {
        const { queries, limit_per_query, input_file: inputFile, region, settings } = task;

        function onFileUploaded(input_file, enrich, output_extension) {
          updateTask({ input_file, enrich, queries: '', settings: { ...settings, output_extension } });
        }

        return <Space size={16} className='w-100' direction='vertical'>
          <Col xs={24} lg={12}>
            <Item
              required
              label={<Space align='center'>
              Product links, Product IDs
                <InfoTooltip title={<>Copy links to products</>}/>
              </Space>}
            >
              <CustomQueries
                value={queries}
                onChange={(v) => updateTask({ queries: v })}
                inputFile={inputFile}
                onFileUploaded={onFileUploaded}
                placeholder='https://www.google.com/shopping/product/7016166685587850095&#10;7016166685587850095'
                error={invalidFields.includes('queries')}
              />
            </Item>
          </Col>

          <Col xs={24} lg={6} xl={4}>
            <Item label='Limit per one product'>
              <Space align='center' className='first-space-item-full'>
                <InputNumber
                  className='w-100'
                  min={1}
                  step={100}
                  value={limit_per_query}
                  onChange={(v) => updateTask({ limit_per_query: parseInt(v) || 1 })}
                />
                <InfoTooltip title='Parameter specifies the limit of reviews from one product.'/>
              </Space>
            </Item>
          </Col>

          <Col>
            <Item
              label='Country (some products only available in specific regions)'
              wrapperCol={{
                xs: { span: 24 },
                lg: { span: 6 },
                xl: { span: 4 },
              }}
            >
              <GoogleLocationSelect value={region} onChange={updateTask} />
            </Item>
          </Col>

        </Space>;
      }}
    />
  );
}
