import React, { useEffect, useState } from 'react';
import { notification, message, Input, Card, Form, InputNumber, Button, Space, Skeleton, Spin } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Testimonials from '@shared/Components/Testimonials';
import apiPayment from '@api/apiPayment';
import { useMedia } from '@hooks/useMedia';

const { Item } = Form;
const { Meta } = Card;

export default function CryptoPayment() {
  const { paymentId } = useParams();

  const [paymentDetails, setPaymentDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { isDesktop } = useMedia();

  const { payment_status, pay_address, actually_paid, price_amount, price_currency, pay_amount, pay_currency } = paymentDetails;
  const payCurrency = pay_currency ? pay_currency.toUpperCase() : '';
  const priceCurrency = price_currency ? price_currency.toUpperCase() : '';
  const description = <Skeleton loading={!pay_amount || !payCurrency} active>
    <Trans i18nKey='description.sendCrypto'>
      Please send <strong>{{ pay_amount }}</strong> {{ payCurrency }} (<strong>exact amount</strong>) to the deposit address displayed below. The deposit will be credited to your account balance.
    </Trans>
  </Skeleton>;
  const payCurrencyAddress = <Trans i18nKey='description.cryptoAddress'>{{ payCurrency }} address</Trans>;
  const isPaid = actually_paid > 0;

  useEffect(() => {
    if (paymentId) {
      fetchData();

      setInterval(() => {
        fetchData(false);
      }, 30000);
    }
  }, [paymentId]);

  async function fetchData(showLoading = true) {
    if (showLoading) setLoading(true);
    try {
      const { data } = await apiPayment.getCryptoPayment(paymentId);
      setPaymentDetails(data);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      if (showLoading) setLoading(false);
    }
  }

  function onCopy(selector, copyText = '') {
    let element = document.querySelector(selector);
    element.select();
    document.execCommand('copy');

    message.success(`${copyText} ` + t('description.copied'));
  }

  const paymentCardTitle = (
    <Space className='w-100 justifySpaceBetween pt-16 pb-16' direction={isDesktop ? 'horizontal' : 'vertical'}>
      <span>{`${t('title.payment')} #${paymentId} [`}
        {payment_status ? t(`title.${payment_status}`, payment_status) : <Spin size='small' />}
        {']'}
      </span>
      <Button className='w-100' loading={loading} onClick={fetchData} type='default'>
        <Trans i18nKey='title.refresh' />
      </Button>
    </Space>
  );

  return (
    <Space
      className='space-items-full justifySpaceBetween'
      align={isDesktop ? 'start' : 'center'}
      size={40}
      direction={isDesktop ? 'horizontal' : 'vertical'}
    >
      <Card className='w-100' title={paymentCardTitle}>
        <Space className='w-100' direction='vertical' size={24}>
          <Meta description={isPaid ? <Trans i18nKey='description.cryptoDeposited'>
            You successfully deposited <strong>{{ actually_paid }} {{ payCurrency }}</strong>.
            Navigate to the profile page to see your account balance (it might take up to <strong>10 minutes</strong> to confirm your transaction).
          </Trans> : description} />
          <Form name='basic' layout='vertical'>
            <Space direction='vertical' size={16} className='w-100'>
              <Item label={t('title.amount') + ' ' + payCurrency}>
                <Input
                  readOnly
                  className='pay-amount'
                  value={pay_amount}
                  onClick={() => onCopy('.pay-amount', t('title.amount') + ' ' + payCurrency)}
                  disabled={loading}
                />
              </Item>
              <Item label={t('title.amount') + ' ' + priceCurrency}>
                <Input
                  readOnly
                  className='price-amount'
                  value={price_amount}
                  onClick={() => onCopy('.price-amount', t('title.amount') + ' ' + priceCurrency)}
                  disabled={loading}
                />
              </Item>
              <Item label={payCurrencyAddress}>
                <Input
                  readOnly
                  className='pay-address lightGrayBG'
                  value={pay_address}
                  onClick={() => onCopy('.pay-address')}
                  disabled={loading}
                />
              </Item>
              {isPaid &&
              <Item label={t('title.amountPaid') + ' ' + payCurrency}>
                <InputNumber
                  readOnly
                  className='actually-paid w-100'
                  value={actually_paid}
                  disabled={loading}
                />
              </Item>
              }
            </Space>
          </Form>
        </Space>
      </Card>
      <Testimonials/>
    </Space>
  );
}

