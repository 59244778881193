import React from 'react';

import SimpleService from '@Common/SimpleService';

export default function EmailFinder() {
  return (
    <SimpleService
      unitName='email'
      title='Email Addresses Finder'
      subTitle='Finds professional emails by names'
      apiTag='Email-Related/paths/~1emails-finder/get'
      serviceName='email_finder_service'
      queriesPlaceholder='John Doe company.com&#10;John company.com'
      queriesLabel='Full name + company domain'
      limitType={null}
      enrichmentFields={['full name', 'first name', 'family name', 'domain', 'website']}
    />
  );
}
