import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/tr';
import 'moment/locale/uk';
import qs from 'query-string';
import { cloneDeep } from 'lodash';

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function trimTaskMetadata(task) {
  const formatedTask = { ...task };

  delete formatedTask.user_id;
  delete formatedTask.customer_email;

  return formatedTask;
}

export function getTags(metadata) {
  if (metadata) {
    const { tags } = metadata;

    if (tags) {
      if (Array.isArray(tags)) {
        return tags;
      } else {
        return tags.split(', ');
      }
    }
  }

  return [];
}

export function parseQueries(text) {
  return text.split(/\r?\n/).filter((el) => { return !!el; });
}

export function toQueries(value) {
  if (Array.isArray(value)) {
    return arrayToText(value);
  } else {
    return value;
  }
}

export function splitTaskByKey(task, splitInto, key) {
  const results = [];
  const items = cloneDeep(task[key]);
  delete task[key];
  let index = 1;

  for (const chunkItems of chunkArray(items, Math.ceil(items.length / splitInto))) {
    const newTask = cloneDeep(task);
    const { tags = [] } = newTask;
    const formatedTags = typeof tags === 'string' ? tags.split(', ') : tags;

    results.push({
      ...newTask,
      tags: [...formatedTags, `subtask ${index}`],
      [key]: chunkItems
    });
    index += 1;
  }

  return results;
}

export function arrayToText(array) {
  return array.join('\n');
}

export function delay(duration) {
  return new Promise(resolve => setTimeout(resolve, duration));
}

export function toArray(value) {
  if (!value) return [];
  return typeof value === 'string' ? [value] : value;
}

export function isValidEmail(email) {
  return /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(email);
}

export function getServiceUrl(serviceName) {
  if (serviceName in servicesUrls) {
    return servicesUrls[serviceName];
  }
  return serviceName.replaceAll('_service', '').replaceAll('_v2', '').replaceAll('_v3', '').replaceAll('_v4', '').replaceAll('_', '-');
}

export function updateLocalStorage(key, value) {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
}

export const servicesUrls = {
  'google_maps_service': 'googleMapsV1',
  'google_maps_service_v2': 'googleMaps',
  'google_maps_service_v3': 'googleMaps',
  'google_maps_and_contacts_service_v2': 'googleMaps',
  'google_maps_reviews_service_v2': 'googleReviewsV2',
  'google_maps_reviews_service_v3': 'googleReviews',
  'google_maps_traffic_service': 'googleTraffic',
  'google_products_reviews_service': 'google-shopping-reviews',
  'domains_service': 'emails-scraper',
  'ai_scraper_service': 'universal-scraper',
  'ai_scraper_service_v2': 'universal-scraper',
};

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export function chunkArray(items, itemsInChunk) {
  return items.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / itemsInChunk);
    if(!resultArray[chunkIndex]) resultArray[chunkIndex] = [];
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
}

export function getEstimateDuration(duration, splitInto) {
  moment.locale(getLanguage());

  if (!duration) return '';
  const durationDiff = moment.duration(duration / (splitInto > 1 ? splitInto : 1), 'minutes').humanize({ h: 48 });
  const numberValue = (durationDiff.match(/\d/g) || []).join('');
  return numberValue ? durationDiff.replace(numberValue, `${parseInt(parseInt(numberValue) * 0.7)} - ${parseInt(parseInt(numberValue) * 2.5)}`) : durationDiff;
}

export function formatBalance(balance) {
  const formattedNumber = Math.abs(balance).toLocaleString();
  return balance < 0
    ? `-$${formattedNumber}`
    : `$${formattedNumber}`;
}

export function addUrlParams(url, params, ignoreNull = false) {
  const mergedParams = { ...qs.parse(url), ...params };
  if (ignoreNull) {
    // eslint-disable-next-line no-unused-vars
    return qs.stringify(Object.fromEntries(Object.entries(mergedParams).filter(([key, value]) => value !== null)));
  } else {
    return qs.stringify(mergedParams);
  }
}

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export function getLanguage(defaultLanguage='en') {
  return localStorage.getItem('i18nextLng') || defaultLanguage;
}

export function isCredibleUser() {
  return localStorage.getItem('credibleUser');
}

export function setCredibleUser() {
  return localStorage.setItem('credibleUser', '1');
}

export function setPaidUser(isPaidUser) {
  updateLocalStorage('paidUser', JSON.stringify(isPaidUser));
}

export function setGoogleIntegrationExists(value) {
  updateLocalStorage('googleIntegration', JSON.stringify(value));
}

export function isGoogleIntegrationExists() {
  return localStorage.getItem('googleIntegration') === 'true';
}

export function isPaidUser() {
  return localStorage.getItem('paidUser') === 'true';
}

export function isRecentlyUsedNTimes(key, n = 3, timeLimitInMinutes = 15) {
  let storedTimestamps = JSON.parse(localStorage.getItem(key)) || [];

  const currentTime = new Date().getTime();
  const timeLimitInMilliseconds = timeLimitInMinutes * 60 * 1000;
  storedTimestamps = storedTimestamps.filter(timestamp => currentTime - timestamp <= timeLimitInMilliseconds);

  storedTimestamps.push(currentTime);

  storedTimestamps = storedTimestamps.filter(timestamp => currentTime - timestamp <= timeLimitInMilliseconds);

  localStorage.setItem(key, JSON.stringify(storedTimestamps));

  return storedTimestamps.length > n;
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
}

export function toCamelCase(postalCode) {
  return postalCode
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
}

export function pluralize(text) {
  if (!text) return '';

  const word = text.split('/')[0];

  if (/(s|x|z|ch|sh)$/i.test(word)) {
    return word + 'es';
  }

  if (/[^aeiou]y$/i.test(word)) {
    return word.slice(0, -1) + 'ies';
  }

  if (/f$|fe$/i.test(word)) {
    return word.replace(/fe?$/i, 'ves');
  }

  if (/[^aeiou]o$/i.test(word)) {
    return word + 'es';
  }

  return word + 's';
}

export function displayWords(words, N, emptyText = '') {
  if (!Array.isArray(words) || words.length === 0) return emptyText;

  if (words.length <= N) {
    return words.join(', ');
  } else {
    return `${words.slice(0, N).join(', ')} and ${words.length - N} more`;
  }
}
