import React from 'react';

import SimpleService from '@Common/SimpleService';
import { services } from '@shared/data/services';

export default function PhonesEnricher() {
  return (
    <SimpleService
      unitName='phone'
      title='Phone Numbers Enricher'
      subTitle='Returns phones carrier data (name/type), validates phones, ensures messages deliverability'
      serviceName='phones_enricher_service'
      queriesLabel='Phone numbers (international)'
      queriesPlaceholder='+1 281 236 8208&#10;442812368208'
      learnMoreUrl='https://outscraper.com/phone-number-lookup'
      apiTag='Phone-Related/paths/~1phones-enricher/get'
      limitType={null}
      enrichmentFields={services.phones_enricher_service.input_fields}
      supportedEnrichmentServices={[]}
    />
  );
}
