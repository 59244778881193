import React from 'react';

import { services } from '@shared/data/services';
import SimpleService from '@Common/SimpleService';

export default function LinkedinPostsScraper() {
  return (
    <SimpleService
      unitName={services.linkedin_posts.unit}
      queriesLabel='URLs to company profiles, company IDs'
      title={services.linkedin_posts.name}
      subTitle={services.linkedin_posts.description}
      serviceName='linkedin_posts'
      queriesPlaceholder='outscraper&#10;https://www.linkedin.com/company/outscraper/'
      apiTag='Others/paths/~1linkedin~1posts/get'
    />
  );
}
