import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Tag, Empty } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { ClockCircleOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import ReactJson from 'react-json-view';

import { trimTaskMetadata, getTags } from '@utils/utils';
import { removeScheduledTask, pauseScheduledTask, resumeScheduledTask, fetchScheduledTasks } from '@redux/actions/tasksActions';
import ScheduledTaskActions from './ScheduledTaskActions';
import TaskId from './TasksTable/TaskId';

export default function ScheduledTasksTable({ selected = false, refresh = 0 }) {
  const scheduledTasks = useSelector(state => state.tasksReducer.scheduledTasks);
  const loading = useSelector(state => state.tasksReducer.loading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const scheduledTasksColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <TaskId id={id}/>,
    },
    {
      title: t('title.status'),
      dataIndex: '',
      key: 'status',
      render: (results, record) =>
        <Tag color={record.next_run ? 'blue' : 'red'}>
          {record.next_run ? t('title.active'): t('title.paused')}
        </Tag>
    },
    {
      title: t('title.tags'),
      dataIndex: 'metadata',
      key: 'tags',
      render: (metadata) => <>{getTags(metadata).map(tag => (<Tag key={tag}>{tag}</Tag>))}</>,
    },
    {
      title: t('title.crontab'),
      dataIndex: 'crontab',
      key: 'crontab',
    },
    {
      title: t('title.nextRun'),
      dataIndex: 'next_run',
      key: 'next_run',
      render: next_run => next_run && <Moment local format='YYYY/MM/DD HH:mm'>{next_run}</Moment>,
    },
    {
      title: t('title.actions'),
      dataIndex: 'id',
      key: 'actions',
      render: (id, record) => <ScheduledTaskActions
        task={record}
        onRemove={onRemoveTask}
        onPause={onPauseTask}
        onResume={onResumeTask}
      />
    },
  ];

  useEffect(() => {
    document.title = 'Scheduled Tasks';
    if (selected) dispatch(fetchScheduledTasks());
  }, [selected, refresh]);

  async function onRemoveTask(taskId) {
    await dispatch(removeScheduledTask(taskId));
    await dispatch(fetchScheduledTasks());
  }

  async function onPauseTask(taskId) {
    await dispatch(pauseScheduledTask(taskId));
    await dispatch(fetchScheduledTasks());
  }

  async function onResumeTask(taskId) {
    await dispatch(resumeScheduledTask(taskId));
    await dispatch(fetchScheduledTasks());
  }

  return (
    <Table
      rowKey='id'
      loading={loading}
      scroll={{ x: 'max-content' }}
      columns={scheduledTasksColumns}
      dataSource={scheduledTasks}
      expandable={{ expandedRowRender: task => <ReactJson src={trimTaskMetadata(task.metadata)} name='metadata'/> }}
      locale={{ emptyText: <Empty description={<Trans i18nKey='description.emptyScheduledTasks'>You have no scheduled tasks yet. You can schedule a task by clicking on the clock <ClockCircleOutlined /> button.</Trans>} /> }}
    />
  );
}

ScheduledTasksTable.propTypes = {
  selected: PropTypes.bool,
  refresh: PropTypes.number,
};
