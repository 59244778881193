import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Space } from 'antd';
import config from 'react-global-configuration';
import { UpOutlined } from '@ant-design/icons/lib/icons';
import { DownOutlined } from '@ant-design/icons';

import TaskResult from './TaskResult';

const { Text } = Typography;
const taskExpiredAfter = config.get('taskExpiredAfter');

export default function TaskResults({ showEmptyTaskInfo, value = [], created }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const expired = Date.now() - Date.parse(created) > taskExpiredAfter * 60 * 60 * 24 * 1000;
  const downloadableResults = value.length > 1 ? value.filter(r => r.file_url) : value;
  const totalUsage = value.reduce((a, b) => a + b.quantity, 0);

  function onShowEmptyTaskInfo() {
    showEmptyTaskInfo(downloadableResults.length > 0 ? downloadableResults[0] : {});
  }

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const getButtonText = (isExpanded, count) =>
    `${isExpanded ? 'Hide' : 'Show'} enrichments (${count})`;

  return <Space>
    {expired ?
      <Text type='secondary'>Expired</Text>
      :
      <Space align='baseline'>
        {downloadableResults.map((result, i) => (
          <Space direction='vertical' size={8} key={`result-${i}`}>
            {value.length > 0 &&
              <TaskResult result={value[0]} resultKey='result-0' />
            }
            {value.length > 1 && (
              <Button
                type='link'
                onClick={handleToggle}
                icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
                iconPosition='end'
                style={{ padding: '0' }}
              >
                {getButtonText(isExpanded, value.length - 1)}
              </Button>
            )}
            {isExpanded &&
                value.slice(1).map((result, i) => (
                  <div key={`result-${i + 1}`}>
                    <TaskResult result={result} resultKey={`result-${i + 1}`}/>
                  </div>
                ))
            }
            {!totalUsage && showEmptyTaskInfo && <Space>
              <span>Task returned no results. <Button type='link' onClick={onShowEmptyTaskInfo} style={{ padding: '0' }}>Why did it happen?</Button></span>
            </Space>}
          </Space>
        ))}
      </Space>
    }
  </Space>;
}

TaskResults.propTypes = {
  showEmptyTaskInfo: PropTypes.func,
  value: PropTypes.array,
  taskId: PropTypes.string,
  created: PropTypes.string,
  size: PropTypes.string,
  showInfo: PropTypes.bool,
  type: PropTypes.string,
  metadata: PropTypes.object,
  doRefresh: PropTypes.func,
};
