import React from 'react';

import SimpleService from '@Common/SimpleService';

export default function GoogleMapsContributorReviews() {
  return (
    <SimpleService
      unitName='review'
      title='Google Maps Contributor Reviews Scraper'
      subTitle='Returns reviews from a list of profiles'
      serviceName='google_maps_contributor_reviews_service'
      queriesPlaceholder='116992800507045820329&#10;https://www.google.com/maps/contrib/109743434949154249800/reviews'
      queriesLabel='Contributor IDs, Contributor URLs'
    />
  );
}
