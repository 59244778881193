import React, { useState } from 'react';
import queryString from 'query-string';
import { notification, Form, Button, Popconfirm, Select, InputNumber, Space, Card, Alert, Row, Col } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import Testimonials from '@shared/Components/Testimonials';
import Mixpanel from '@analytics/mixpanel';
import apiPayment from '@api/apiPayment';
import { useMedia } from '@hooks/useMedia';

const { Item } = Form;
const { Option } = Select;
const { Meta } = Card;

const MIN_AMOUNT = 25;

export default function MakeCryptoPayment() {
  const location = useLocation();
  const navigate = useNavigate();
  const { amount: queryAmount } = queryString.parse(location.search);
  const [currency, setCurrency] = useState('btc');
  const [amount, setAmount] = useState(Math.max(Number(queryAmount) || 100, MIN_AMOUNT));
  const [loading, setLoading] = useState(false);
  const formatedCurrency = currency.toLocaleUpperCase();
  const bonus = 4;
  const { t } = useTranslation();
  const { isDesktop } = useMedia();

  function handleSetAmount(value) {
    setAmount(Number(value) || MIN_AMOUNT);
  }

  async function createCryptoPayment() {
    setLoading(true);
    try {
      const { data } = await apiPayment.createCryptoPayment(amount, currency);
      const { payment_id } = data;
      navigate(`/profile/crypto-payment/${payment_id}`);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }

    Mixpanel.track('Crypto payment intent', { currency });
  }

  return (
    <Card>
      <Row gutter={[32, 32]} align='middle' justify='center' wrap>
        <Col xs={24} md={12} >
          <Space direction='vertical' size={24} style={{ maxWidth: isDesktop ? 450 : '100%' }}>
            <Meta
              title={t('title.addCredits')}
              description={<Trans i18nKey='description.addCreditsCrypto'>Deposit funds to your account balance with cryptocurrency and receive a {{ bonus }}% bonus from your payment.</Trans>}
            />
            {currency === 'btc' && (
              <Alert
                message='Transaction Processing Time'
                description='Note: Transactions on BTC network may take longer to process (up to 60 minutes). For faster processing, consider using USDT or USDC on BSC, POLYGON, or SOL networks.'
                type='warning'
                showIcon
                className='mt-8'
              />
            )}
            <Form name='basic' layout='vertical'>
              <Space direction='vertical' size={16} className='w-100'>
                <Item label={t('title.currency', 'Currency')}>
                  <Select value={currency} onChange={setCurrency}>
                    <Option value='btc'>BTC</Option>
                    <Option value='eth'>ETH (Mainnet)</Option>
                    <Option value='usdtbsc'>USDT (BSC)</Option>
                    <Option value='usdttrc20'>USDT (TRC20)</Option>
                    <Option value='usdtmatic'>USDT (POLYGON)</Option>
                    <Option value='usdtsol'>USDT (SOL)</Option>
                    <Option value='usdc'>USDC (ETH)</Option>
                    <Option value='usdcbsc'>USDC (BSC)</Option>
                    <Option value='usdcmatic'>USDC (POLYGON)</Option>
                    <Option value='usdcsol'>USDC (SOL)</Option>
                    <Option value='xmr'>Monero (XMR)</Option>
                  </Select>
                </Item>
                <Item label={t('title.amount') + ' USD'}>
                  <InputNumber
                    className='w-100'
                    formatter={value => `$ ${value}`}
                    value={amount}
                    min={MIN_AMOUNT}
                    max={5000}
                    step={10}
                    onChange={handleSetAmount}
                  />
                </Item>
                <Item>
                  <Popconfirm
                    title={<Trans i18nKey='description.addCreditsConfirm'>Pay ${{ amount }} with {{ formatedCurrency }}?</Trans>}
                    okText={t('action.ok')}
                    cancelText={t('action.cancel')}
                    onConfirm={createCryptoPayment}
                  >
                    <Button className='w-100' loading={loading} title={`Create ${formatedCurrency} address and pay`} type='primary'>
                      <Trans i18nKey='action.addCreditsCrypto'>Create {{ formatedCurrency }} address and pay</Trans>
                    </Button>
                  </Popconfirm>
                </Item>
              </Space>
            </Form>
          </Space>
        </Col>
        <Col xs={24} md={12} className='justifyFlexEnd'>
          <div style={{ maxWidth: isDesktop ? 450 : '100%' }}>
            <Testimonials />
          </div>
        </Col>
      </Row>
    </Card>
  );
}
