import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form, TreeSelect, Empty, ConfigProvider, Space, Row, Col } from 'antd';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

import config from 'react-global-configuration';
import { fetchLocations } from '@redux/actions/queriesActions';
import { onSetDefaultRegion } from '@utils/defaultProps';
import InfoTooltip from '@shared/Components/InfoTooltip';
import RegionSelect from '../Google/RegionSelect';
import { ErrorMessage } from '@shared/Components/ErrorMessage.jsx';
import { usePrevious } from '@hooks/usePrevious';

const { Item } = Form;

const { SHOW_PARENT } = TreeSelect;

const StyledCtrl = styled.span`
  color: #1890ff;
  display: inline-block;
  cursor: pointer;
`;

const StyledButton = styled.button`
  color: #262626;
  text-decoration: underline;
`;

const controlDataProps = {
  disableCheckbox: true,
  checkable: false,
  disabled: true
};

function LocationsSelect({ task, onUpdate, singleRegion = null, error }) {
  const { locations, region = singleRegion, useZipCodes } = task;

  const regionLocations = useSelector(state => state.queriesReducer.countryLocations)[region] || [];
  const supportsZipCodes = useSelector(state => state.queriesReducer.countrySupportsZipCodes)[region];
  const loading = useSelector(state => state.queriesReducer.loading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hasRegionLocations = regionLocations.length > 0;
  const hasLocations = locations && locations.length > 0;
  const prevRegion = usePrevious(region);

  const treeControlData = [
    {
      title:
        hasLocations ? (
          <StyledCtrl onClick={clearLocations}>Unselect all</StyledCtrl>
        ) : (
          <StyledCtrl onClick={selectAllLocations}>Select all</StyledCtrl>
        ),
      value: 'xxx',
      ...controlDataProps
    }
  ];

  const customizeRenderEmpty = () => <div><br /><Empty description={t('action.selectCountry')} /><br /></div>;

  useEffect(() => {
    if (region) {
      if (prevRegion && region !== prevRegion) {
        dispatch(fetchLocations(region));
      } else {
        if (regionLocations.length === 0) {
          dispatch(fetchLocations(region));
        }
      }
    }
  }, [region]);

  useEffect(() => {
    if (region && hasRegionLocations) {
      if (!hasLocations) {
        onLocationsChange(regionLocations.map(({ value }) => value), null, {}, supportsZipCodes);
      }
    }
  }, [regionLocations]);

  function onLocationsChange(locations, _, extra = {}, selectUseZipCodes = null) {
    const { triggerValue, checked } = extra;
    const payload = { locations: locations.filter(l => l) };

    if (!useZipCodes && checked && triggerValue && triggerValue.split('>').length > 3) {
      payload.useZipCodes = true;
    }
    if (selectUseZipCodes) payload.useZipCodes = true;

    onUpdate(payload);
  }

  function onRegionChange(newRegion) {
    if (newRegion !== region) {
      let { enrichments = [] } = task;

      if (newRegion !== 'US' && ['companies_data', 'whitepages_phones'].some(service => enrichments.includes(service))) {
        enrichments = enrichments.filter(el => !['companies_data', 'whitepages_phones'].includes(el));
      }

      onUpdate({ region: newRegion, locations: [], enrichments });
      onSetDefaultRegion(newRegion);
    }
  }

  function selectAllLocations() {
    onLocationsChange(regionLocations.map(({ value }) => value));
  }

  function clearLocations() {
    onLocationsChange([]);
  }

  function filterTreeNode(inputValue, { value }) {
    if (!value) return false;

    if (inputValue.length > 2) {
      return value.toLowerCase().includes(inputValue.toLowerCase());
    }
    else {
      return value.split('>').some((v) => v.toLowerCase().startsWith(inputValue.toLowerCase()));
    }
  }
  if (error) console.error('Location error:', error);

  return <Space size={4} direction='vertical' className='w-100'>
    <Item>
      <Row gutter={[8, 8]}>
        {!singleRegion &&
          <Col xs={24} lg={5}>
            <Item>
              <RegionSelect hideTooltip size='large' value={region} onChange={onRegionChange} error={error} />
            </Item>
          </Col>
        }
        <Col xs={24} lg={19}>
          <Space align='center' className='nobreak first-space-item-full'>
            <ConfigProvider renderEmpty={region ? null : customizeRenderEmpty}>
              <TreeSelect
                allowClear
                treeCheckable
                showSearch
                treeLine
                autoClearSearchValue={false}
                maxTagCount={3}
                size='large'
                placeholder={loading ? (t('title.loading') + '...') : t('action.states')}
                loading={loading}
                disabled={loading}
                treeData={region ? [...treeControlData, ...regionLocations] : []}
                value={locations}
                onChange={onLocationsChange}
                showCheckedStrategy={SHOW_PARENT}
                dropdownStyle={{ maxHeight: 360, overflow: 'auto' }}
                filterTreeNode={filterTreeNode}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.target.value) {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                status={error ? 'error' : ''}
              />
            </ConfigProvider>
            <InfoTooltip title={
              <Trans i18nKey='description.selectLocations'>
                Based on the following <a target='_blank' rel='noreferrer' href={config.get('locationsFileUrl')}>data</a>.
                <br /><br />
                Click &quot;Custom locations&quot; to use your own locations instead. Make sure you are using a country inside each query in case of using your own locations.
              </Trans>
            } />
          </Space>
        </Col>
      </Row>
    </Item>
    {error && (
      <ErrorMessage />
    )}
    {!singleRegion && <Space>
      <label><Trans i18nKey='title.try' />: </label>
      <StyledButton type='button' className='link-button' onClick={() => onRegionChange('US')}>
        US,
      </StyledButton>
      <StyledButton type='button' className='link-button' onClick={() => onRegionChange('DE')}>
        DE,
      </StyledButton>
      <StyledButton type='button' className='link-button' onClick={() => onRegionChange('IT')}>
        IT,
      </StyledButton>
      <StyledButton type='button' className='link-button' onClick={() => onRegionChange('GB')}>
        GB,
      </StyledButton>
      <StyledButton type='button' className='link-button' onClick={() => onRegionChange('CA')}>
        CA
      </StyledButton>
    </Space>}
  </Space>;
}

LocationsSelect.propTypes = {
  task: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  singleRegion: PropTypes.string,
  error: PropTypes.bool,
};

export default React.memo(LocationsSelect, (prevProps, nextProps) => {
  const { locations: prevLocations, region: prevRegion } = prevProps.task;
  const { locations: nextLocations, region: nextRegion } = nextProps.task;

  return prevProps.error === nextProps.error &&
    prevRegion === nextRegion &&
    prevLocations.length === nextLocations.length &&
    prevLocations.every((el, i) => el === nextLocations[i]);
});
