import React from 'react';

import SimpleService from '@Common/SimpleService';

export default function RedditSearchScraper() {
  return (
    <SimpleService
      unitName='post'
      title='Reddit Search Scraper'
      subTitle='Returns search results'
      serviceName='reddit_search'
      queriesPlaceholder='bitcoin&#10;https://www.reddit.com/search/?q=HIVE'
      defaultParams={{ sort: 'relevance' }}
      recentCutoffSorting={'new'}
      sortingOptions={[
        { value: 'relevance', text: 'Relevance' },
        { value: 'hot', text: 'Hot' },
        { value: 'top', text: 'Top' },
        { value: 'new', text: 'New' },
        { value: 'comments', text: 'Comment count' },
      ]}
    />
  );
}
