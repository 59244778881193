import {
  START_REQUEST,
  STOP_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_BILLINGINFO_SUCCESS,
  FETCH_INTEGRATIONS_SUCCESS,
  FETCH_USER_SETTINGS_SUCCESS,
  UPDATE_USER_SETTINGS_SUCCESS,
} from '../actions/profileActions';

export default (state = { profile: {}, billingInfo: {}, integrations: {}, plans: [], userSettings: {} }, action) => {
  switch (action.type) {
  case START_REQUEST: {
    return {
      ...state,
      loading: true,
    };
  }
  case STOP_REQUEST: {
    return {
      ...state,
      loading: false,
    };
  }
  case FETCH_PROFILE_SUCCESS: {
    return {
      ...state,
      profile: action.profile,
    };
  }
  case FETCH_BILLINGINFO_SUCCESS: {
    return {
      ...state,
      billingInfo: action.billingInfo,
    };
  }
  case FETCH_INTEGRATIONS_SUCCESS: {
    return {
      ...state,
      integrations: action.integrations,
    };
  }
  case FETCH_USER_SETTINGS_SUCCESS: {
    return {
      ...state,
      userSettings: action.userSettings,
    };
  }
  case UPDATE_USER_SETTINGS_SUCCESS: {
    return {
      ...state,
      userSettings: action.userSettings,
    };
  }
  default:
    return state;
  }
};
