import React from 'react';

import SimpleService from '@Common/SimpleService';

export default function GooglePlacesUpdate() {
  return (
    <SimpleService
      unitName='place'
      title='Places Update Service'
      subTitle='Updates places information'
      serviceName='places_update_service'
      queriesPlaceholder='{"city": "palo alto"}'
    />
  );
}
