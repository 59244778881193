import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Href({ href, children, disabled = false, external = false }) {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = location.pathname === href;

  function onClick(event) {
    event.preventDefault();

    if (disabled) return;

    if (external) {
      window.open(href, '_blank');
    } else {
      navigate(href);
    }
  }

  return <a href={href} className={isActive ? 'active-link' : ''} onClick={onClick}>{children}</a>;
}

Href.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  disabled: PropTypes.bool,
  external: PropTypes.bool,
};
