import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popconfirm, Button, Tooltip, Space } from 'antd';
import { LogoutOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';

import ChangeEmailModal from './ChangeEmailModal';
import { useMedia } from '@hooks/useMedia';

export default function ProfileDangerousActions({ onPasswordChangeRequest, onRemove, onLogout, onEmailChanged, loading, passwordChangeRequested, authProvider }) {
  const [isChangeEmailModalVisible, setIsChangeEmailModalVisible] = useState(false);
  const internalProvider = authProvider ? authProvider === 'auth0' : true;
  const notChangeableTooltip = internalProvider ? '' : `Account created with ${authProvider}`;
  const { t } = useTranslation();
  const { isMobileOrTable } = useMedia();

  function handleOnEmailChanged(email) {
    setIsChangeEmailModalVisible(null);
    onEmailChanged(email);
  }

  return <>
    <Space direction={isMobileOrTable ? 'vertical' : 'horizontal'} className='w-100' style={{ justifyContent: 'flex-end' }}>
      <Popconfirm
        title={t('description.areYouSureRemoveAccount')}
        okText={t('action.yes')}
        cancelText={t('action.no')}
        onConfirm={onRemove}
      >
        <Button className='w-100' danger title={t('title.removeAccount')} loading={loading} icon={<DeleteOutlined />}>
          <Trans i18nKey='title.removeAccount' />
        </Button>
      </Popconfirm>
      <Tooltip title={notChangeableTooltip}>
        <Popconfirm
          title={t('title.areYouSure')}
          okText={t('action.yes')}
          cancelText={t('action.no')}
          disabled={!internalProvider}
          onConfirm={onPasswordChangeRequest}
        >
          <Button
            className='w-100'
            ghost
            type='primary'
            title={t('title.changePassword')}
            loading={loading}
            disabled={passwordChangeRequested || !internalProvider}
            icon={<EditOutlined />}
          >
            <Trans i18nKey='title.changePassword' />
          </Button>
        </Popconfirm>
      </Tooltip>
      <Button
        className='w-100'
        ghost
        type='primary'
        title={t('title.changeEmail')}
        loading={loading}
        disabled={isChangeEmailModalVisible === null}
        onClick={() => setIsChangeEmailModalVisible(true)}
        icon={<EditOutlined />}
      >
        <Trans i18nKey='title.changeEmail' />
      </Button>
      <Popconfirm
        title={t('title.areYouSure')}
        okText={t('action.yes')}
        cancelText={t('action.no')}
        onConfirm={onLogout}
      >
        <Button
          className='w-100'
          ghost
          type='primary'
          title={t('title.logOut')}
          icon={<LogoutOutlined />}
        >
          <Trans i18nKey='title.logOut' />
        </Button>
      </Popconfirm>
    </Space>
    <ChangeEmailModal
      open={isChangeEmailModalVisible}
      onCancel={() => setIsChangeEmailModalVisible(false)}
      onChanged={handleOnEmailChanged}
      authProvider={authProvider}
      internalProvider={internalProvider}
    />
  </>;
}

ProfileDangerousActions.propTypes = {
  onPasswordChangeRequest: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onEmailChanged: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  passwordChangeRequested: PropTypes.bool,
  authProvider: PropTypes.string,
};
