import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { Button, Popconfirm, message, Space, Dropdown } from 'antd';
import {
  RedoOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  CopyOutlined,
  LinkOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { encode } from 'js-base64';
import { useSelector, useDispatch } from 'react-redux';
import config from 'react-global-configuration';

import Mixpanel from '@analytics/mixpanel';
import { isPaidUser } from '@utils/utils';
import { openSpreadsheet } from '@utils/helpers/openSpreadsheet';
import PremiumFeatureTooltip from '@shared/Components/PremiumFeatureTooltip';
import { fetchIntegrations, connectIntegration } from '@redux/actions/profileActions';
import { GoogleSheets, MenuKebab } from '@assets/icons';
import './Tasks.scss';

const StyledButton = styled(Button)`
  margin: 0 2px;
  width: 10px;
`;
StyledButton.defaultProps = { type: 'default', size: 'small', shape: 'round' };

const supportedExtensions = new Set(['csv', 'xlsx']);
const taskExpiredAfter = config.get('taskExpiredAfter');

export default function TaskActions({ task = {}, onRerun = null, onRecreate = null, onRemove = null, onSchedule = null }) {
  const { id, metadata = {}, status, created, results = [] } = task;
  const expired = Date.now() - Date.parse(created) > taskExpiredAfter * 60 * 60 * 24 * 1000;
  const integrations = useSelector(state => state.profileReducer.integrations);
  const { google = {} } = integrations;
  const firstResult = results[0] || {};
  const { spreadsheet_url, quantity = 0 } = firstResult;
  const fileUrl = firstResult.file_url || '';
  const isUsage = !!quantity && quantity > 0;

  const restarted = status === 'RESTARTED';
  const isSuccess = status === 'SUCCESS';
  const isRerunnable = (metadata.queries || (metadata.locations && metadata.categories) && !restarted);
  const isOldTask = !restarted && Math.abs(new Date() - new Date(created)) / 36e5 > 24;
  const [loading, setLoading] = React.useState(false);
  const paidUser = isPaidUser();
  const { t } = useTranslation();

  const { settings = {} } = metadata;
  const { output_extension } = settings;
  const isSupportedExtension = supportedExtensions.has(output_extension);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIntegrations());
  }, [dispatch]);

  function handleOnRecreate() {
    onRecreate(metadata);
  }

  function handleOnRerun() {
    onRerun(id);
  }

  function handleOnSchedule() {
    onSchedule(id);
  }

  function handleOnRemove() {
    onRemove(id);
  }

  function handleShare() {
    Mixpanel.track('Share task');

    navigator.clipboard.writeText(`${window.origin}/shared/${encode(localStorage.getItem('id'))}/${id}`);

    message.success(t('message.publicLink'));
  }

  async function openSpreadsheetClick() {
    setLoading(true);
    if (isEmpty(google)) await dispatch(connectIntegration('', 'google', '', '', true));
    await openSpreadsheet([id], dispatch);
    setLoading(false);
  }

  function handleOpenSpreadsheetClick() {
    openSpreadsheetClick();
  }

  const moreActions = (
    <Space direction='vertical' className='more-actions'>
      <Button
        className='share-task'
        icon={<LinkOutlined />}
        disabled={!isSuccess || !isUsage}
        onClick={handleShare}
      >
        {t('action.share')}
      </Button>
      {onRerun &&
        <Popconfirm
          title={t('action.restart') + '?'}
          okText={t('action.ok')}
          cancelText={t('action.cancel')}
          onConfirm={handleOnRerun}
          disabled={!isRerunnable}
        >
          <Button
            className='restart-task'
            icon={<RedoOutlined />}
            disabled={!isRerunnable || restarted || (!isOldTask && !isSuccess)}
          >
            {t('action.restart')}
          </Button>
        </Popconfirm>
      }
      {onSchedule &&
        <PremiumFeatureTooltip hideIcon isPaidUser={paidUser}>
          <Button
            className='schedule-task'
            icon={<ClockCircleOutlined />}
            onClick={handleOnSchedule}
            disabled={!isRerunnable || !paidUser}
          >
            {t('action.schedule')}
          </Button>
        </PremiumFeatureTooltip>
      }
      {onRecreate &&
        <Button
          className='copy-task'
          icon={<CopyOutlined />}
          disabled={!isRerunnable}
          onClick={handleOnRecreate}
        >
          {t('action.useAsTemplate')}
        </Button>
      }
      {onRemove &&
        <Popconfirm
          title={t('action.remove') + '?'}
          okText={t('action.ok')}
          cancelText={t('action.cancel')}
          onConfirm={handleOnRemove}
        >
          <Button
            className='remove-task'
            icon={<CloseCircleOutlined />}
            disabled={restarted || (!isOldTask && !isSuccess)}
          >
            {t('action.remove')}
          </Button>
        </Popconfirm>
      }
    </Space>
  );

  return <Space size={4} className='task-actions'>
    <StyledButton
      title='Open table in Google Sheets'
      disabled={!isSuccess || !isSupportedExtension || quantity <= 0 || expired}
      icon={<GoogleSheets />}
      href={spreadsheet_url || undefined}
      loading={loading}
      target={spreadsheet_url ? '_blank' : undefined}
      onClick={spreadsheet_url ? undefined : handleOpenSpreadsheetClick}
    />
    <StyledButton
      type='primary'
      title={t('action.upload')}
      disabled={!isUsage || expired}
      icon={<DownloadOutlined />}
      href={fileUrl}
    />
    <Dropdown dropdownRender={() => moreActions} trigger={['click']}>
      <StyledButton
        className='more-actions'
        title='Open more'
        icon={<MenuKebab />}
      />
    </Dropdown>
  </Space>;
}

TaskActions.propTypes = {
  task: PropTypes.object.isRequired,
  onRerun: PropTypes.func,
  onRecreate: PropTypes.func,
  onRemove: PropTypes.func,
  onSchedule: PropTypes.func,
  onShare: PropTypes.func,
};
