import React from 'react';
import { Form, Input } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import BaseService from '@Common/BaseService';
import CustomQueries from '@Common/Selects/CustomQueries';

const { Item } = Form;
const { TextArea } = Input;

export default function SMSMessaging() {
  return (
    <BaseService
      title='SMS Messaging'
      subTitle='Allows to send SMS to list of phone numbers'
      serviceName='messaging_service'
      startTaskButtonTitle='Send'
      unitName='SMS'
      limitType={null}
      hasResult={false}
      startTaskButtonIcon={<SendOutlined />}
      taskExtraDefaultParams={{ message: '' }}
      FormBody={({ updateTask, task, invalidFields }) => {
        const { queries, message, inputFile, settings } = task;

        function onFileUploaded(input_file, enrich, output_extension) {
          updateTask({ input_file, enrich, queries: '', settings: { ...settings, output_extension } });
        }

        return <>
          <Item required label='Phone numbers' style={{ maxWidth: 600 }}>
            <CustomQueries
              value={queries}
              onChange={(v) => updateTask({ queries: v })}
              inputFile={inputFile}
              onFileUploaded={onFileUploaded}
              placeholder='1 281 236 8208&#10;1 281 236 2248'
              error={invalidFields.includes('queries')}
            />
          </Item>
          <Item required label='Message' style={{ maxWidth: 300 }}>
            <TextArea
              rows={4}
              value={message}
              onChange={({ target }) => updateTask({ message: target.value })}
              placeholder='The contents of your message'
              maxLength={160}
            />
          </Item>
        </>;
      }}
    />
  );
}
