import React from 'react';

import { services } from '@shared/data/services';
import SimpleService from '@Common/SimpleService';

export default function YouTubeChannelsScraper() {
  return (
    <SimpleService
      limitType={null}
      unitName='search'
      title={services.youtube_channel_service.name}
      subTitle={services.youtube_channel_service.description}
      serviceName='youtube_channel_service'
      apiTag='YouTube/paths/~1youtube-channel/get'
      queriesPlaceholder='https://www.youtube.com/@outscraper&#10;outscraper'
      learnMoreUrl='https://outscraper.com/youtube-channel-scraper/'
    />
  );
}
