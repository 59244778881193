import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Space, Col, Row, Form } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { ErrorMessage } from '@shared/Components/ErrorMessage.jsx';

const { Item } = Form;

export default function StartStopLocationsSelect({ locations = [], onChange, invalidFields }) {
  function onStartLocationChange({ target: { value } }, index) {
    const newLocations = [...locations];
    newLocations[index][0] = value;
    onChange(newLocations);
  }

  function onStopLocationChange({ target: { value } }, index) {
    const newLocations = [...locations];
    newLocations[index][1] = value;
    onChange(newLocations);
  }

  function onRemove(index) {
    const newLocations = [...locations];
    newLocations.splice(index, 1);
    onChange(newLocations);
  }

  return <Row gutter={[16, 16]} wrap='wrap'>
    {locations.map((location, i) => (
      <Col key={i} xs={24} lg={8} xl={6}>
        <Space direction='vertical' size={[8, 8]} className='trafficStartStopLocation'>
          <Item required label='Start location'>
            <Input
              placeholder='Intersection of 19th Ave and Santiago St, San Francisco'
              value={location[0]}
              onChange={(e) => onStartLocationChange(e, i)}
              status={invalidFields.includes('startLocation') ? 'error' : ''}
            />
            {invalidFields.includes('startLocation') && <ErrorMessage />}
          </Item>

          <Item required label='Stop location'>
            <Input
              placeholder='Intersection of 19th Ave and Irving St, San Francisco'
              value={location[1]}
              onChange={(v) => onStopLocationChange(v, i)}
              status={invalidFields.includes('stopLocation') ? 'error' : ''}
            />
            {invalidFields.includes('stopLocation') && <ErrorMessage />}
          </Item>

          {locations.length > 1 && (
            <Button
              className='w-100'
              type='link'
              icon={<DeleteOutlined />}
              onClick={() => onRemove(i)}
            >
              Delete
            </Button>
          )}
        </Space>
      </Col>
    ))}
  </Row>;
}

StartStopLocationsSelect.propTypes = {
  locations: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  invalidFields: PropTypes.array
};
