import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, InputNumber, Row, Space, Tooltip } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import GA from '@analytics/ga';
import MakePaymentButton from './MakePaymentButton';
import MakePaymentWithNMIButton from './MakePaymentWithNMIButton';
import MakePaymentWithPayPalButton from './MakePaymentWithPayPalButton';
import MakePaymentWithCryptoButton from './Crypto/MakePaymentWithCryptoButton';
import { useMedia } from '@hooks/useMedia';

export const MIN_AMOUNT = 10;

export default function AddCredits({ defaultValue = 25, loading, region, onMakePayment, hidePayPal, crypto, creditCardButtonTitle, showIcon = true, riskLevel = 0, emailVerified = false, source, callback, onPaymentMade, secondaryTab, minValue = MIN_AMOUNT }) {
  const [amount, setAmount] = useState(Math.max(defaultValue, minValue));
  const [waiting, setWaiting] = useState(false);
  const internalLoading = waiting || loading;
  const { t } = useTranslation();
  const { isMobile } = useMedia();

  useEffect(() => {
    if (minValue > amount) {
      setAmount(minValue);
    }
  }, [minValue]);

  let maxValue = 10000;
  if (riskLevel === 2) {
    maxValue = 50;
  } else if (riskLevel === 3) {
    maxValue = 25;
  }

  function handleOnMakePayment() {
    GA.track('button', source ? `Payment intent from ${source}` : 'Payment intent', 'Card');

    if (callback) {
      callback();
    }

    if (secondaryTab) {
      window.open(`/profile?deposit=${amount}&secondary_tab=true`, '_blank', 'noopener,noreferrer');
    }

    if (onMakePayment) {
      onMakePayment(amount, region);
    }
  }

  function handleSetAmount(value) {
    setAmount(Number(value) || minValue);
  }

  function handleOnPaymentMade() {
    if (callback) {
      callback();
    }
    if (onPaymentMade) {
      onPaymentMade();
    }
  }

  async function delayedCallback() {
    if (callback) {
      setWaiting(true);
      await new Promise(r => setTimeout(r, 10000));
      setWaiting(false);
      callback();
    }
  }

  const getTooltipTitle = (riskLimit, fee) => (
    !emailVerified ? (
      <Trans i18nKey='title.email_unverified' />
    ) : riskLevel > riskLimit ? (
      <>Your account is currently flagged with a high-risk status for using this</>
    ) : (
      <><Trans i18nKey='title.fee' />: {fee}%</>
    )
  );

  const nmiButton = (
    <Tooltip className='w-100' placement='top' title={getTooltipTitle(1, 2.3)}>
      <span>
        <MakePaymentWithNMIButton
          showIcon
          title={creditCardButtonTitle}
          amount={amount}
          loading={internalLoading}
          disabled={riskLevel > 1 || !emailVerified}
          riskLevel={riskLevel}
          callback={handleOnPaymentMade}
        />
      </span>
    </Tooltip>
  );

  const stripeButton = (
    <Tooltip className='w-100' placement='top' title={getTooltipTitle(2, 2.9)}>
      <span>
        <MakePaymentButton
          showIcon={showIcon}
          title={creditCardButtonTitle}
          amount={amount}
          loading={internalLoading}
          disabled={riskLevel > 2 || !emailVerified}
          onMakePayment={handleOnMakePayment}
        />
      </span>
    </Tooltip>
  );

  const paymentAmountInput = (
    <Space className='second-space-item-full'>
      {!hidePayPal && <p className='main-text'><Trans i18nKey='title.add' /></p>}
      <InputNumber
        className='w-100'
        formatter={value => `$ ${value}`}
        value={amount}
        min={minValue}
        max={internalLoading ? amount : maxValue}
        step={50}
        onChange={handleSetAmount}
        onPressEnter={handleOnMakePayment}
      />
    </Space>
  );

  return <Row gutter={[isMobile ? 0 : 16, 16]} className='w-100'>
    <Col className={secondaryTab ? '' : 'add-credits-input'} xs={24} md={24} lg={secondaryTab ? 24 : 4}>
      {paymentAmountInput}
    </Col>
    <Col xs={24} md={12} lg={secondaryTab ? 12 : 8} xl={secondaryTab ? 12 : 6}>
      <Space className='w-100' size={11} direction='vertical'>
        {riskLevel > 1 ? [
          <div key='stripe'>{stripeButton}</div>,
          <div key='nmi'>{nmiButton}</div>
        ] : [
          <div key='nmi'>{nmiButton}</div>,
          <div key='stripe'>{stripeButton}</div>
        ]}
        {!hidePayPal && crypto && <><Tooltip title={<><Trans i18nKey='title.bonus' />: 4%, <Trans i18nKey='title.noRefund' /></>} placement='bottom'>
          <span>
            <MakePaymentWithCryptoButton loading={internalLoading} amount={amount} source={source} callback={delayedCallback} secondaryTab={secondaryTab}/>
          </span>
        </Tooltip></>}
      </Space>
    </Col>
    <Col xs={24} md={12} lg={secondaryTab ? 12 : 8} xl={secondaryTab ? 12 : 6}>
      {!hidePayPal && riskLevel < 4 && <Tooltip title={t('title.fee', 'Fee') + ': 3.9%'} placement='top'>
        <span>
          <MakePaymentWithPayPalButton amount={amount} loading={internalLoading} source={source} callback={delayedCallback}/>
        </span>
      </Tooltip>}
    </Col>
  </Row>;
}

AddCredits.propTypes = {
  loading: PropTypes.bool,
  region: PropTypes.string,
  onMakePayment: PropTypes.func,
  onPaymentMade: PropTypes.func,
  callback: PropTypes.func,
  hidePayPal: PropTypes.bool,
  crypto: PropTypes.bool,
  creditCardButtonTitle: PropTypes.string,
  defaultValue: PropTypes.number,
  showIcon: PropTypes.bool,
  riskLevel: PropTypes.number,
  emailVerified: PropTypes.bool,
  personal: PropTypes.bool,
  source: PropTypes.string,
  secondaryTab: PropTypes.bool,
  minValue: PropTypes.number,
};
