import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, Typography, Space } from 'antd';
import { VscSparkleFilled } from 'react-icons/vsc';
import { useTranslation, Trans } from 'react-i18next';

const { Title } = Typography;
const { Item } = Form;

export default function HelperAIModal({ isModalOpen, handleCancel, form, handleSubmit, loading }) {
  const { t } = useTranslation();
  return (
    <Modal
      title={
        <Space size='small' align='center' className='justifyCenter'>
          <VscSparkleFilled className='h3' style={{ color : '6A5ACD' }} />
          <Title level={4}><Title level={4}><Trans i18nKey='title.generateAI'/></Title></Title>
        </Space>
      }
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      centered
      width={675}
    >
      <Form form={form} onFinish={handleSubmit} layout='vertical'>
        <Item
          label={t('title.enterDomain')}
          name='user_input'
          className='mb-16'
          rules={[{ required: true, message: t('title.enterOffer') },
            { max: 250, message: t('title.exceedLimit'), count: 250 }]}>
          <Input.TextArea placeholder='company.com - we`re selling coffee machines' rows={5} allowClear disabled={loading}/>
        </Item>
        <Button type='primary' htmlType='submit' loading={loading} block>
          <Trans i18nKey='action.send' />
        </Button>
      </Form>
    </Modal>
  );
}

HelperAIModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
