import React from 'react';
import { Col, Row, Modal, Image } from 'antd';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import PremiumFeatureIcon from './PremiumFeatureIcon';

const StyledDiv = styled.div`
  min-height: 465px;
`;

export function showConfirm(premiumFeature = false) {
  Modal.confirm({
    icon: null,
    width: 890,
    style: { top: 30 },
    closable: true,
    okText: <Trans i18nKey='title.addCredits' />,
    onOk: () => {
      if (premiumFeature) window.open('/profile?secondary_tab=true', '_blank', 'noopener,noreferrer');
    },
    cancelButtonProps: { style: { display: 'none' } },
    title: <><Trans i18nKey='title.reasonsToCredit' />{premiumFeature && <>&nbsp;&nbsp;&nbsp;<PremiumFeatureIcon /></>}</>,
    content: <StyledDiv>
      <br/><br/>
      <Row gutter={16}>
        <Col span={3}>
          <Image height={75} src='https://outscraper.com/wp-content/uploads/2023/12/Professional-Services.png' preview={false} />
        </Col>
        <Col span={19}>
          <h3><Trans i18nKey='title.whyAddCredits1' /></h3>
          <Trans i18nKey='description.whyAddCredits1' />
        </Col>
      </Row>
      <br/><br/>

      <Row gutter={16}>
        <Col span={3}>
          <Image height={75} src='https://outscraper.com/wp-content/uploads/2023/12/investment_10434273.png' preview={false} />
        </Col>
        <Col span={19}>
          <h3><Trans i18nKey='title.whyAddCredits2' /></h3>
          <Trans i18nKey='description.whyAddCredits2' />
        </Col>
      </Row>
      <br/><br/>

      <Row gutter={16}>
        <Col span={3}>
          <Image height={75} src='https://outscraper.com/wp-content/uploads/2023/12/strategy_10426299.png' preview={false} />
        </Col>
        <Col span={19}>
          <h3><Trans i18nKey='title.whyAddCredits3' /></h3>
          <Trans i18nKey='description.whyAddCredits3' />
        </Col>
      </Row>
    </StyledDiv>,
  });
}
