import React from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';
import InfoTooltip from '@shared/Components/InfoTooltip';

function TaskResult({ result, resultKey }) {
  return (
    <Space wrap='wrap' size={0} key={resultKey}>
      <Space size={4}>
        <span className='bold'>{result?.product_name}</span> - usage: {result.quantity}
      </Space>
      {!!result.ignored && (
        <Space size={4}>
              , ignored: {result.ignored}
          <InfoTooltip title='Ignored by exact match filter, other filters, duplicates, etc.' />
        </Space>
      )}
    </Space>
  );
}

TaskResult.propTypes = {
  result: PropTypes.object.isRequired,
  resultKey: PropTypes.string.isRequired,
};

export default TaskResult;
