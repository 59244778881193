import React from 'react';
import SimpleService from '@Common/SimpleService';

export default function GraingerProducts() {
  return (
    <SimpleService
      unitName='product'
      title='Grainger Products Scraper'
      subTitle='Returns product listings'
      serviceName='grainger_products'
      learnMoreUrl='https://outscraper.com/grainger-scraper/'
      apiTag='Grainger/paths/~1grainger~1products/get'
      queriesLabel='Product, category or search URLs'
      queriesPlaceholder='https://www.grainger.com/product/DAYTON-Belt-Drive-Prop-Circulator-4C850&#10&#10;https://www.grainger.com/search?searchQuery=safety%20gloves&#10;https://www.grainger.com/category/hvac-and-refrigeration'
    />
  );
}
