import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import GA from '@analytics/ga';

const StyledButton = styled(Button)`
  border-radius: 4px;
  min-width: 150px;
  width: 100%;
  height: 31px;
`;

export default function MakePaymentWithCryptoButton({ amount, loading, source, callback, secondaryTab }) {
  const navigate = useNavigate();
  function onClick() {
    GA.track('button', source ? `Payment intent from ${source}` : 'Payment intent', 'Crypto');

    if (callback) {
      callback();
    }

    const link = `/profile/crypto-payment?amount=${amount}`;
    if (secondaryTab) {
      window.open(link, '_blank', 'noopener,noreferrer');
    } else {
      navigate(link);
    }
  }

  return (
    <StyledButton
      type='primary'
      loading={loading}
      onClick={onClick}
      className='make-payment-crypto'
    ><Trans i18nKey='title.cryptocurrency' /></StyledButton>
  );
}

MakePaymentWithCryptoButton.propTypes = {
  amount: PropTypes.number,
  loading: PropTypes.bool,
  source: PropTypes.string,
  callback: PropTypes.func,
  secondaryTab: PropTypes.bool,
};
