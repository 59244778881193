import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'query-string';
import { Table, Tag, Empty, Drawer, Tour, Space, Button, Input, notification } from 'antd';
import { RedoOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { GoogleSheets } from '@assets/icons';
import { isEmpty } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import Moment from 'react-moment';
import ReactJson from 'react-json-view';
import config from 'react-global-configuration';
import { useNavigate } from 'react-router-dom';

import { trimTaskMetadata, getTags, getServiceUrl } from '@utils/utils';
import { openSpreadsheet } from '@utils/helpers/openSpreadsheet';
import { setTemplateTask } from '@utils/defaultProps';
import { fetchTasks, rerunTasks, removeTasks, scheduleTask } from '@redux/actions/tasksActions';
import { fetchIntegrations, connectIntegration } from '@redux/actions/profileActions';
import ScheduleModal from '../ScheduleModal';
import TaskResults from './TaskResults';
import TaskProgress from './TaskProgress';
import TaskActions from './TaskActions';
import './Tasks.scss';
import { useMedia } from '@hooks/useMedia';
import TaskId from './TaskId';

const emptyTaskCommonProblems = {
  'Google Maps Data': <>
    1. Try to run your task without the &quot;Exact Match&quot; parameter.<br /><br />
    2. Check<a target='_blank' rel='noopener noreferrer' href='https://outscraper.com/google-maps-data-scraper-filters/'> filters</a>. Make sure it correlates with the real data. Start a small task without filters to see the values it returns. Adjust your filters accordingly.<br /><br />
    3. If using plain queries, make sure you are using countries inside queries. Make sure the country inside your queries correlates with the &quot;Country&quot; parameter.<br /><br />
    4. Make sure the queries you are using can be opened on <a target='_blank' rel='noreferrer' href='https://www.google.com/maps/search/bars,+ny,+usa'>Google Maps</a>.<br /><br />
  </>,
  'Google Maps Reviews': <>
    1. Make sure that the place actually has reviews in Google Maps.<br /><br />
    2. If using links as queries, make sure that this is a <a target='_blank' rel='noreferrer' href='https://goo.gl/maps/mbe1ztdcoBtYwvwn7'>Google Maps link</a> and not a link to Google Search.<br /><br />
    3. Check your task limits and cutoff parameters (date, rating).<br /><br />
  </>
};

export default function TasksTable({ selected = false, refresh = 0, doRefresh }) {
  const navigate = useNavigate();
  const tasks = useSelector(state => state.tasksReducer.tasks);
  const hasMore = useSelector(state => state.tasksReducer.hasMoreTasks);
  const loading = useSelector(state => state.tasksReducer.loading);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isDesktop } = useMedia();

  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [query, setQuery] = useState('');
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastIds, setLastIds] = useState(['']);

  const [showEmptyTaskInfo, setShowEmptyTaskInfo] = useState(false);
  const [emptyTaskBody, setEmptyTaskBody] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingSpreadsheet, setLoadingSpreadsheet] = useState(false);
  const currentLastId = (tasks.length > 0) ? tasks[tasks.length - 1].id : '';

  const [isTourVisible, setIsTourVisible] = useState(false);
  const integrations = useSelector(state => state.profileReducer.integrations);
  const { google = {} } = integrations;

  useEffect(() => {
    dispatch(fetchIntegrations());
  }, [dispatch]);

  const isSelected = selectedRowKeys.length > 0;

  const tasksColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <TaskId id={id} />,
    },
    {
      title: t('title.status'),
      dataIndex: 'status',
      key: 'status',
      render: (results, record) =>
        <Tag color={record.status === 'SUCCESS' ? 'green' : 'blue'}>
          {record.status === 'SUCCESS' ? t('title.completed') : t('title.inProgress')}
        </Tag>
    },
    {
      title: t('title.statictics'),
      dataIndex: 'results',
      key: 'results',
      render: (results, record) => {
        if (record.status !== 'SUCCESS') {
          return <TaskProgress task={record} />;
        }
        return (
          <TaskResults
            value={results}
            taskId={record.id}
            created={record.created}
            showEmptyTaskInfo={onShowEmptyTaskInfo}
            metadata={record.metadata}
            doRefresh={doRefresh}
          />
        );
      },
    },
    {
      title: t('title.tags'),
      dataIndex: 'metadata',
      key: 'tags',
      render: (metadata) => <>{getTags(metadata).map(tag => (<Tag key={tag}>{tag}</Tag>))}</>,
    },
    {
      title: t('title.created'),
      dataIndex: 'created',
      key: 'created',
      render: created => created ? <Moment utc local format='YYYY/MM/DD HH:mm'>{created}</Moment> : '',
    },
    {
      title: t('title.actions'),
      dataIndex: 'id',
      key: 'actions',
      fixed: 'right',
      render: (id, record) => {
        return (
          <TaskActions
            task={record}
            onRerun={onRerunTask}
            onRemove={onRemoveTask}
            onRecreate={onRecreateTask}
            onSchedule={() => {
              setIsScheduleModalVisible(!isScheduleModalVisible);
              setEditingTaskId(id);
            }}
          />
        );
      }
    },
  ];

  const expiredAfter = config.get('taskExpiredAfterSoft');

  const handleRerunTasks = () => {
    onRerunTasks(selectedRowKeys);
    setSelectedRowKeys([]);
  };

  const handleRemoveTasks = () => {
    onRemoveTasks(selectedRowKeys);
    setSelectedRowKeys([]);
  };

  const handleExportToSpreadsheet = () => {
    const { availableTasks, unavailableTasks } = selectedRowKeys.reduce(
      (acc, taskId) => {
        const task = new Map(tasks.map(task => [task.id, task])).get(taskId);
        if (!task) return acc;

        const { status, created, results = [] } = task;
        const isExpired = Date.now() - Date.parse(created) > config.get('taskExpiredAfter') * 24 * 60 * 60 * 1000;
        const hasValidResults = results[0]?.quantity > 0;

        if (status === 'SUCCESS' && hasValidResults && !isExpired) {
          acc.availableTasks.push(taskId);
        } else {
          acc.unavailableTasks.push(taskId);
        }

        return acc;
      },
      { availableTasks: [], unavailableTasks: [] }
    );

    if (unavailableTasks.length > 0) {
      const message = unavailableTasks.length === selectedRowKeys.length
        ? 'Cannot export to spreadsheet: All selected tasks are unavailable'
        : `${unavailableTasks.length} task(s) cannot be exported (unavailable)`;

      notification.error({ message, description: 'Tasks must be completed, have results, and not be expired' });
    }

    async function openSpreadsheetClick() {
      setLoadingSpreadsheet(true);
      if (isEmpty(google)) await dispatch(connectIntegration('', 'google', '', '', true));
      await openSpreadsheet(availableTasks, dispatch);
      setLoadingSpreadsheet(false);
    }

    if (availableTasks.length > 0) openSpreadsheetClick();

  };

  useEffect(() => {
    if (tasks && tasks.length > 0 && !localStorage.getItem('tasksTutorial')) setIsTourVisible(true);
  }, [tasks]);

  useEffect(() => {
    document.title = t('title.tasks') + ' | ' + config.get('company');

    if (selected) {
      dispatch(fetchTasks('', currentPageSize, query));

      const intervalId = setInterval(() => {
        let innerPage = 0;
        let innerCurrentPageSize = 0;
        let innerQuery = '';

        setCurrentPage((currentPage) => { innerPage = currentPage; return currentPage; });
        setCurrentPageSize((currentPageSize) => { innerCurrentPageSize = currentPageSize; return currentPageSize; });
        setQuery((query) => { innerQuery = query; return query; });

        if (innerPage === 1 && !innerQuery && innerCurrentPageSize === 10) {
          dispatch(fetchTasks('', innerCurrentPageSize, innerQuery));
        }
      }, 1000 * 60 * 10);

      return () => clearInterval(intervalId);
    }
  }, [selected, refresh, searchValue]);

  function onFinishTour() {
    setIsTourVisible(false);
    localStorage.setItem('tasksTutorial', '1');
  }

  function onPagination({ current, pageSize }) {
    if (pageSize !== currentPageSize || current !== currentPage) {
      if (pageSize !== currentPageSize) {
        setLastIds(['']);
      }

      if (current > lastIds.length) {
        setLastIds([...lastIds, currentLastId]);
        dispatch(fetchTasks(currentLastId, pageSize, query));
      } else {
        dispatch(fetchTasks(lastIds[current - 1], pageSize, query));
      }

      setCurrentPage(current);
      setCurrentPageSize(pageSize);
    }
  }

  function onRecreateTask(metadata) {
    const { service_name } = metadata;
    const serviceUrl = getServiceUrl(service_name);

    if (!metadata.tags) {
      delete metadata.tags;
    }
    delete metadata.notify_on_finish;
    delete metadata.customer_email;
    delete metadata.queries_amount;
    delete metadata.user_id;
    delete metadata.id;

    if (serviceUrl) {
      setTemplateTask(metadata);
      navigate(`/${serviceUrl}`);
    }
  }

  function onRerunTask(taskId) {
    onRerunTasks([taskId]);
  }

  function onRerunTasks(taskIds) {
    dispatch(rerunTasks(taskIds));
  }

  async function onRemoveTask(taskId) {
    await onRemoveTasks([taskId]);
  }

  async function onRemoveTasks(taskIds) {
    await dispatch(removeTasks(taskIds));

    dispatch(fetchTasks('', currentPageSize));
    setCurrentPage(1);
    setLastIds(['']);
  }

  const onSearch = (e) => {
    const value = e.target.value.trim();
    setQuery(value);
    setSearchValue(value);

    if (value) {
      navigate({ search: qs.stringify({ query: value }) });
    } else {
      navigate({ search: qs.stringify({}) });
    }
  };

  function onScheduleTask(crontab) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch(scheduleTask(editingTaskId, crontab, timezone));

    setEditingTaskId(null);
    setIsScheduleModalVisible(false);
  }

  function onShowEmptyTaskInfo(result) {
    const { product_name } = result;
    setShowEmptyTaskInfo(true);
    setEmptyTaskBody(emptyTaskCommonProblems[product_name]);
  }

  function onHideEmptyTaskInfo() {
    setShowEmptyTaskInfo(false);
  }

  const filterTasksByStatus = (status) => {
    return tasks
      .filter(task => (status === 'SUCCESS' ? task.status === status : task.status !== 'SUCCESS'))
      .map(task => task.id);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    selections: [
      {
        key: 'all',
        text: 'All',
        onSelect: () => {
          setSelectedRowKeys(tasks.map(task => task.id));
        },
      },
      {
        key: 'none',
        text: 'None',
        onSelect: () => {
          setSelectedRowKeys([]);
        },
      },
      {
        key: 'started',
        text: 'In progress',
        onSelect: () => {
          setSelectedRowKeys(filterTasksByStatus('STARTED'));
        },
      },
      {
        key: 'success',
        text: 'Completed',
        onSelect: () => {
          setSelectedRowKeys(filterTasksByStatus('SUCCESS'));
        },
      }
    ]
  };

  return <>
    <Space direction={isDesktop ? 'horizontal' : 'vertical'} size={24} className='mb-16 w-100 justifySpaceBetween'>
      <Space size={16} className='task-table-header-actions'>
        <span className='btn'>
          <Trans i18nKey='title.selected' />: {selectedRowKeys.length}
        </span>
        <Input
          placeholder={t('action.searchByTag')}
          allowClear
          size='large'
          onChange={onSearch}
        />
      </Space>
      {isSelected &&
        <Space size={8} className='space-items-full'>
          <Button className='w-100' onClick={handleExportToSpreadsheet} disabled={!isSelected}  loading={loadingSpreadsheet} icon={<GoogleSheets />}>
            Export to Google Spreadsheet
          </Button>
          <Button className='w-100' onClick={handleRerunTasks} disabled={!isSelected} icon={<RedoOutlined />}>
            <Trans i18nKey='action.restart' />
          </Button>
          <Button className='w-100' onClick={handleRemoveTasks} disabled={!isSelected} icon={<CloseCircleOutlined />}>
            <Trans i18nKey='action.remove' />
          </Button>
        </Space>
      }
    </Space>
    <Table
      className='taskTable'
      rowKey='id'
      scroll={{ x: 'max-content' }}
      loading={loading}
      columns={tasksColumns}
      dataSource={tasks}
      rowSelection={rowSelection}
      expandable={{ expandedRowRender: task => <ReactJson src={trimTaskMetadata(task.metadata)} name='metadata' /> }}
      pagination={{
        pageSize: currentPageSize,
        current: currentPage,
        total: lastIds.length * currentPageSize + hasMore - (currentPage < lastIds.length),
        pageSizeOptions: [10, 20],
        showSizeChanger: true,
      }}
      onChange={onPagination}
      locale={{ emptyText: <Empty description={t('description.tasksEmpty')} /> }}
    />
    <p className='help-p1'>
      * <Trans i18nKey='description.tasks'>Results will be available for {{ expiredAfter }} days to download</Trans>
    </p>

    <ScheduleModal open={isScheduleModalVisible} onSave={onScheduleTask} onCancel={() => setIsScheduleModalVisible(false)} />

    <Drawer
      closable={false}
      title={t('description.whyNoResutls')}
      open={showEmptyTaskInfo}
      onClose={onHideEmptyTaskInfo}
    >
      {emptyTaskBody}<br />
      If none of the above help, please consider checking <a target='_blank' rel='noreferrer' href='https://outscraper.com/category/tutorials/'>tutorials</a> or contact support (Left Panel -&gt; Help -&gt; Support).
    </Drawer>

    <Tour
      open={isTourVisible}
      onClose={onFinishTour}
      steps={
        [{
          target: () => document.querySelector('.more-actions'),
          title: t('tour.copyTask'),
        },
        {
          target: () => document.querySelector('.more-actions'),
          title: t('tour.scheduleTask'),
        },
        {
          target: () => document.querySelector('.more-actions'),
          title: t('tour.shareTask'),
        }]
      }
    />
  </>;
}

TasksTable.propTypes = {
  selected: PropTypes.bool,
  refresh: PropTypes.number,
  doRefresh: PropTypes.func,
};
