import { notification } from 'antd';

import apiPayment from '@api/apiPayment';

export const START_REQUEST = 'START_REQUEST';
export const STOP_REQUEST = 'STOP_REQUEST';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';

export const startRequest = () => ({ type: START_REQUEST });
export const stopRequest = () => ({ type: STOP_REQUEST });

export const fetchInvoices = (lastId = '') => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiPayment.fetchInvoices(lastId);
    dispatch({ type: FETCH_INVOICES_SUCCESS, invoices: data.items, hasMoreInvoices: data.has_more });
  } catch (error) {
    notification.error({ message: error.message });
  } finally {
    dispatch(stopRequest());
  }
};
