import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Space } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Trans } from 'react-i18next';

import config from 'react-global-configuration';
import PaymentInfo from '@shared/Components/PaymentInfo';
import Testimonials from '@shared/Components/Testimonials';
import { showConfirm } from '@shared/Components/ReasonsToAddCredits';
import AddCredits, { MIN_AMOUNT as minAmount } from '@Profile/AddCredits/AddCredits';
import { useMedia } from '@hooks/useMedia';

export default function TaskConfirmationNotAllowed({ status, onActionCallback, loading, risky = false, depositRequired }) {
  const emailUnverified = status === 'email_unverified' || (localStorage.getItem('emailVerified') !== 'true' && risky);
  const email = localStorage.getItem('email');
  const MIN_AMOUNT = Math.max(depositRequired || minAmount, 1);
  const allowCr = email ? !(email.endsWith('ov') || email.endsWith('du')) : false;

  const { isDesktop } = useMedia();

  useEffect(() => {
    if (emailUnverified) return;
    onShowConfirm();
  }, []);

  function onShowConfirm() {
    showConfirm();
  }

  return (
    <Space direction='vertical' size={16} className='mt-24 second-space-item-full justifyCenter'>
      {emailUnverified && <Space className='w-100 mb-24' direction='vertical' align='center'>
        <FontAwesomeIcon size='4x' color='#d1cfcf' icon={faEnvelope}/>
        <h4 className='h4'><Trans i18nKey='title.verifyEmail'/><Trans i18nKey='dot'>.</Trans></h4>
      </Space>}
      {(status === 'open_invoices' || status === 'excessive_open_invoices') ? (
        <Space className='w-100 mb-24' direction={isDesktop ? 'horizontal' : 'vertical'} size={16}>
          <h4 className='h4'><Trans i18nKey='title.closeInvoices'/>.</h4>
          <Button
            className='w-100'
            type='primary'
            href='/invoices'
            target='_blank'
            rel='noopener noreferrer'
          ><Trans i18nKey='action.viewInvoices'/>
          </Button>
        </Space>
      )
        : !emailUnverified ? (
          <Space className='w-100' direction='vertical' size={16}>
            <AddCredits
              secondaryTab
              emailVerified={!emailUnverified}
              crypto={allowCr}
              defaultValue={10}
              loading={loading}
              riskLevel={risky ? 3 : 1}
              source='task confirmation'
              callback={onActionCallback}
              minValue={MIN_AMOUNT}
            />
            <Space direction='vertical' size={0}>
              <p className='help-p2'>
                      * <Trans i18nKey='description.usingCreditsAfterFree'>Credits are utilized only after exceeding the <a
                  href={`${config.get('pricingUrl')}#free`} target='_blank' rel='noopener noreferrer'>free tier</a>.
                      Minimum deposit: ${{ MIN_AMOUNT }}</Trans>.
              </p>
              <p className='help-p2'>
                      * <Trans i18nKey='description.usingCredits'>Use your deposit for any services at listed <a
                  href={`${config.get('pricingUrl')}`} target='_blank' rel='noopener noreferrer'>prices</a>, with no
                      expiration</Trans>.
              </p>
            </Space>
            {!risky && <>
              <Divider plain><Trans i18nKey='title.or'/></Divider>
              <Space className='justifyCenter'>
                <Button
                  icon={<CreditCardOutlined/>}
                  type='primary'
                  href='/billing-info?checkout=true'
                  onClick={onActionCallback}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                    &nbsp;<Trans i18nKey='action.connectCard'/>
                </Button>
              </Space>
              <Space direction='vertical' size={0}>
                <p className='help-p2'>
                  * <Trans i18nKey='description.connectCard'>
                  Connect a card and receive invoices only for usage that exceeds the <a
                      href={`${config.get('pricingUrl')}#free`} target='_blank' rel='noopener noreferrer'>free tier</a>
                  </Trans>.
                </p>
                <p className='help-p2'>
                  * <Trans i18nKey='description.PCI'>
                  We use a <a target='_blank' rel='noopener noreferrer'
                      href='http://www.visa.com/splisting/searchGrsp.do?companyNameCriteria=stripe'>PCI Service
                  Provider Level 1</a> which is the highest grade of payment security
                  </Trans>.
                </p>
              </Space>
            </>}
            <PaymentInfo />
          </Space>
        ) : null
      }
      {!emailUnverified && <Testimonials/>}
    </Space>
  );
}

TaskConfirmationNotAllowed.propTypes = {
  status: PropTypes.string.isRequired,
  onActionCallback: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  risky: PropTypes.bool,
  depositRequired: PropTypes.number,
};
