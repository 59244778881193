import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { notification, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { PayPalButton } from 'react-paypal-button-v2';
import config from 'react-global-configuration';

import Mixpanel from '@analytics/mixpanel';
import GA from '@analytics/ga';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBillingInfo } from '@redux/actions/profileActions';

const CLIENT_ID = config.get('payPalClientId');

export default function MakePaymentWithPayPalButton({ amount = 100, source, loading, callback }) {
  const dispatch = useDispatch();
  const email = localStorage.getItem('email');

  useEffect(() => {
    dispatch(fetchBillingInfo());
  }, []);

  const billingInfo = useSelector(state => state.profileReducer.billingInfo);
  const { address } = billingInfo;
  const { city, country, postal_code, state, line1, line2 } = address || {};

  function onDeposited() {
    notification.success({ message: 'Payment is successful. Please wait 1-3 minutes till the funds will appear in your account balance.' });

    if (callback) {
      callback();
    }
  }

  return <Spin indicator={<LoadingOutlined />} spinning={loading}>
    <PayPalButton
      options={{ clientId: CLIENT_ID, disableFunding: 'paylater' }}
      style={{
        layout: 'vertical',
        label: 'paypal',
        tagline: false,
        height: 38
      }}
      createOrder={(data, actions) => {
        Mixpanel.track('Paypal payment intent', { amount });
        GA.track('button', source ? `Payment intent from ${source}` : 'Payment intent', 'Paypal');

        const payerData = {
          email_address: email,
          ...(country && {
            address: {
              address_line_1: line1,
              address_line_2: line2,
              admin_area_2: city,
              admin_area_1: state,
              postal_code: postal_code,
              country_code: country
            }
          })
        };

        console.log('toEmail:', localStorage.getItem('toEmail'));
        console.log('email:', localStorage.getItem('email'));

        console.log('reference_id', localStorage.getItem('toEmail') || localStorage.getItem('email'));

        return actions.order.create({
          intent: 'CAPTURE',
          payer: payerData,
          purchase_units: [{
            reference_id: localStorage.getItem('toEmail') || localStorage.getItem('email'),
            description: 'Make a payment to add credits into account balance.',
            amount: { currency_code: 'USD', value: amount }
          }],
          application_context: { shipping_preference: 'NO_SHIPPING' },
        });
      }}
      onApprove={(data, actions) => {
        return actions.order.capture().then((details) => {
          onDeposited(details.payer);
        });
      }}
    />
  </Spin>;
}

MakePaymentWithPayPalButton.propTypes = {
  amount: PropTypes.number.isRequired,
  source: PropTypes.string,
  loading: PropTypes.bool,
  callback: PropTypes.func,
};
