import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Checkbox, Col, Space } from 'antd';
import { Trans } from 'react-i18next';

import { setCustomQueries, getCustomQueries } from '@utils/defaultProps';
import InfoTooltip from '@shared/Components/InfoTooltip';
import CustomItemsSelect from './CustomItemsSelect';
import UploadFile from '../UploadFile';
import { usePrevious } from '@hooks/usePrevious';

const StyledLabel = styled.div`
  padding: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledCheckbox = styled(Checkbox)`
  font-size: 12px;
  padding-top: 12px;
`;

function CustomQueries({ value, inputFile, onChange, onFileUploaded, placeholder, inputFileHeader, inputFileHeaderChange, enrichmentFields, error }) {
  const newVersion = !!inputFileHeaderChange;
  const prevValue = usePrevious(value);
  const page_url = window.location.href.split('/')[3];
  useEffect(() => {
    if (!value) {
      if (prevValue) {
        setCustomQueries('', page_url);
      } else if (prevValue === undefined) {
        const cacheValue = getCustomQueries(page_url);

        if (cacheValue) {
          onChange(cacheValue);
        }
      }
    }
  }, [value]);

  function handleOnChange(newValue) {
    onChange(newValue);
    setCustomQueries(newValue, page_url);
  }

  function handleInputFileHeaderChange({ target }) {
    inputFileHeaderChange(target.checked);
  }

  return <Space className='w-100' direction='vertical'>
    {!inputFile && <>
      <CustomItemsSelect
        autoFocus
        value={value}
        onChange={handleOnChange}
        placeholder={placeholder}
        error={error}
      />
      {onFileUploaded && <StyledLabel>
        <Trans i18nKey='description.selectFile' />
        <InfoTooltip title={<Trans i18nKey='description.queriesOrFile' />} />
      </StyledLabel>}
    </>}
    {onFileUploaded && <Col xs={24} lg={6}>
      <UploadFile
        onReady={onFileUploaded}
        enrichmentFields={enrichmentFields}
        placeholder={placeholder}
      />
    </Col>}
    {inputFile && newVersion &&
      <StyledCheckbox
        checked={inputFileHeader}
        onChange={handleInputFileHeaderChange}
      >File with header</StyledCheckbox>
    }
  </Space>;
}

CustomQueries.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  inputFile: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFileUploaded: PropTypes.func,
  placeholder: PropTypes.string,
  inputFileHeader: PropTypes.bool,
  inputFileHeaderChange: PropTypes.func,
  enrichmentFields: PropTypes.array,
  queriesPlaceholder: PropTypes.string,
  error: PropTypes.bool,
};

export default React.memo(CustomQueries, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value && prevProps.inputFile === nextProps.inputFile && prevProps.error === nextProps.error;
});
