import React from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';

const { Item } = Form;

export default function EmailFormItem() {
  return <Item
    name='email'
    rules={[{ type: 'email', required: true, message: 'Please enter your email' }]}
    validateTrigger='onSubmit'
  >
    <Input prefix={<MailOutlined />} placeholder='Enter your email' />
  </Item>;
}
