/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';
import { QuestionCircle } from '@assets/icons';

function InfoTooltip({ title, marginLeft= 0, marginRight= 0 }) {
  return <Tooltip title={title}>
    <span className='alignCenter' style={{ marginLeft, marginRight, width: '14px', height: '14px' }}>
      <QuestionCircle/>
    </span>
  </Tooltip>;
}

InfoTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
};

export default React.memo(InfoTooltip, (prevProps, nextProps) => {
  return true;
});
