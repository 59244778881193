import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Select, Space } from 'antd';

import { services } from '@shared/data/services';
import PremiumFeatureTooltip from '@shared/Components/PremiumFeatureTooltip';
import SimpleService from '@Common/SimpleService';
import PreferredContactsItem from '@Common/PreferredContactsItem';
import { useTranslation, Trans } from 'react-i18next';

const { Item } = Form;
const { Option } = Select;

export default function DomainsExtractor() {
  const { t } = useTranslation();

  return (
    <SimpleService
      limitType={null}
      unitName='domain'
      tourSteps={[
        {
          target: () => document.querySelector('.queries-input'),
          title: t('tour.queriesDomainInput'),
        }
      ]}
      title='Emails & Contacts Scraper'
      subTitle='Finds emails, social links, phones, and other contacts from websites'
      serviceName='domains_service'
      queriesPlaceholder='company.com&#10;company2.com'
      queriesLabel='Domains or URLs'
      videoTutorialLink='https://www.youtube.com/embed/_BAlDfZFVPA'
      learnMoreUrl='https://outscraper.com/emails-scraper'
      tutorialLink='https://outscraper.com/how-to-scrape-emails'
      apiTag='Email-Related/paths/~1emails-and-contacts/get'
      enrichmentFields={services.domains_service.input_fields}
      supportedEnrichmentServices={[]}
      defaultEnrichments={['emails_validator_service', 'whitepages_phones']}
      excludedEnrichmentServices={['contacts_finder_service']}
      ExtraItems={({ updateTask, task, isPaidUser }) => <ExtraItems updateTask={updateTask} task={task} isPaidUser={isPaidUser} />}
    />
  );
}

export function ExtraItems({ updateTask, task, isPaidUser, isModal = false }) {
  const { t } = useTranslation();

  const { general_emails } = task;

  const labelWithTooltip = (
    <PremiumFeatureTooltip isPaidUser={!isPaidUser === false}>
      <span>{t('title.type')}</span>
    </PremiumFeatureTooltip>
  );

  return (
    <Space className='w-100' size={16} direction='vertical'>
      <Col xs={24} lg={isModal ? 24 : 6} xl={isModal ? 24 : 4}>
        <PreferredContactsItem value={task} onChange={updateTask} isPaidUser={!isPaidUser === false} />
      </Col>
      <Col xs={24} lg={isModal ? 24 : 6} xl={isModal ? 24 : 4}>
        <Item label={labelWithTooltip}>
          <Select
            placeholder={t('title.any')}
            allowClear
            value={general_emails}
            disabled={!isPaidUser}
            onChange={(v) => updateTask({ general_emails: v })}
          >
            <Option key='true' value={true}><Trans i18nKey='title.general' /> (info@, support@, etc.)</Option>
            <Option key='false' value={false}><Trans i18nKey='title.notGeneral' /> (paul@, john@, etc.)</Option>
          </Select>
        </Item>
      </Col>
    </Space>
  );
}

ExtraItems.propTypes = {
  updateTask: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
  isPaidUser: PropTypes.bool,
  isModal: PropTypes.bool,
};
