import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Input, Button, Popconfirm, notification } from 'antd';

import apiProfile from '@api/apiProfile';
import { isValidEmail } from '@utils/utils';
import { updateGodMode, removeAndBlockUserByIP } from '@redux/actions/profileActions';

const StyledButton = styled(Button)`
  margin-left: 8px;
`;

export default function SupportBlock({ profile, loading, auth }) {
  const { ie } = profile;
  const [investigationEmail, setInvestigationEmail] = useState(ie);
  const investigationEmailNotValid = investigationEmail && !isValidEmail(investigationEmail);
  const dispatch = useDispatch();
  const isTurnOff = ie === investigationEmail || !investigationEmail;

  async function onUpdateGodMode(email) {
    try {
      dispatch(updateGodMode(email, afterChangeGodMode));
    } catch (error) {
      notification.error({ message: error.message });
    }
  }

  async function afterChangeGodMode() {
    await auth.renewSession();
    window.location.reload();
  }

  async function onTurnOnGodMode() {
    await onUpdateGodMode(investigationEmail);
  }

  async function onGodModeChange() {
    if (isTurnOff) {
      await onUpdateGodMode(null);
    } else {
      await onTurnOnGodMode();
    }
  }

  async function onVerifyEemail() {
    try {
      await apiProfile.verifyEmail(localStorage.getItem('accessToken'));
      notification.success({ message: 'Verified' });
    } catch (error) {
      notification.error({ message: error.message });
    }
  }

  async function onRemoveAndBlockUserByIP() {
    try {
      dispatch(removeAndBlockUserByIP(investigationEmail));
    } catch (error) {
      notification.error({ message: error.message });
    }
  }

  return <>
    <h3>God Mode Email</h3>
    <span className='nobreak'>
      <Input
        disabled={loading}
        style={{ width: 300 }}
        value={investigationEmail}
        onChange={({ target: { value } }) => setInvestigationEmail(value)}
      />
      {ie ? <>
        <StyledButton
          title='Turn God Mode off'
          onClick={onGodModeChange}
          loading={loading}
          disabled={investigationEmailNotValid}
        >{isTurnOff ? 'Turn Off' : 'Change'}</StyledButton>
      </> : <>
        <StyledButton
          title='Turn God Mode on (set investigation email)'
          onClick={onTurnOnGodMode}
          loading={loading}
          disabled={investigationEmailNotValid}
        >Turn On</StyledButton>
      </>}

      {ie && <StyledButton
        title='Verify Email'
        onClick={onVerifyEemail}
        loading={loading}
      >Verify Email</StyledButton>}
    </span>

    <br/><br/>
    <br/><br/>
    <br/><br/>
    <Popconfirm
      title={`Are you sure you want to remove ${investigationEmail} and his other accoutns by IP?`}
      onConfirm={onRemoveAndBlockUserByIP}
    >
      <Button
        danger
        title='Remove and Block Users by IP'
        loading={loading}
        disabled={!isTurnOff}
      >Remove and Block Users by IP</Button>
    </Popconfirm>
  </>;
}

SupportBlock.propTypes = {
  profile: PropTypes.object,
  loading: PropTypes.bool,
  auth: PropTypes.object,
};
