export const languagesTop = [
  { code: 'en', name: 'English' },
  { code: 'de', name: 'Deutsch' },
  { code: 'es', name: 'español' },
  { code: 'es-419', name: 'español (Latinoamérica)' },
  { code: 'fr', name: 'français' },
  { code: 'hr', name: 'hrvatski' },
  { code: 'it', name: 'italiano' },
  { code: 'nl', name: 'Nederlands' },
  { code: 'pl', name: 'polski' },
  { code: 'pt-BR', name: 'português (Brasil)' },
  { code: 'pt-PT', name: 'português (Portugal)' },
  { code: 'vi', name: 'Tiếng Việt' },
  { code: 'tr', name: 'Türkçe' },
  { code: 'ru', name: 'русский' },
  { code: 'ar', name: 'العربية' },
  { code: 'th', name: 'ไทย' },
  { code: 'ko', name: '한국어' },
  { code: 'zh-CN', name: '中文 (简体)' },
  { code: 'zh-TW', name: '中文 (繁體)' },
  { code: 'ja', name: '日本語' }
];

export const languagesOther = [
  { code: 'ach', name: 'Acoli' },
  { code: 'af', name: 'Afrikaans' },
  { code: 'ak', name: 'Akan' },
  { code: 'ig', name: 'Asụsụ Igbo' },
  { code: 'az', name: 'azərbaycan' },
  { code: 'ban', name: 'Balinese' },
  { code: 'ceb', name: 'Binisaya' },
  { code: 'xx-bork', name: 'Bork, bork, bork!' },
  { code: 'bs', name: 'bosanski' },
  { code: 'br', name: 'brezhoneg' },
  { code: 'ca', name: 'català' },
  { code: 'cs', name: 'čeština' },
  { code: 'sn', name: 'chiShona' },
  { code: 'co', name: 'Corsican' },
  { code: 'cy', name: 'Cymraeg' },
  { code: 'da', name: 'dansk' },
  { code: 'yo', name: 'Èdè Yorùbá' },
  { code: 'et', name: 'eesti' },
  { code: 'xx-elmer', name: 'Elmer Fudd' },
  { code: 'eo', name: 'esperanto' },
  { code: 'eu', name: 'euskara' },
  { code: 'ee', name: 'Eʋegbe' },
  { code: 'tl', name: 'Filipino' },
  { code: 'fil', name: 'Filipino' },
  { code: 'fo', name: 'føroyskt' },
  { code: 'fy', name: 'Frysk' },
  { code: 'gaa', name: 'Ga' },
  { code: 'ga', name: 'Gaeilge' },
  { code: 'gd', name: 'Gàidhlig' },
  { code: 'gl', name: 'galego' },
  { code: 'gn', name: 'Guarani' },
  { code: 'xx-hacker', name: 'Hacker' },
  { code: 'ht', name: 'Haitian Creole' },
  { code: 'ha', name: 'Hausa' },
  { code: 'haw', name: 'ʻŌlelo Hawaiʻi' },
  { code: 'bem', name: 'Ichibemba' },
  { code: 'rn', name: 'Ikirundi' },
  { code: 'id', name: 'Indonesia' },
  { code: 'ia', name: 'interlingua' },
  { code: 'xh', name: 'isiXhosa' },
  { code: 'zu', name: 'isiZulu' },
  { code: 'is', name: 'íslenska' },
  { code: 'jw', name: 'Jawa' },
  { code: 'rw', name: 'Kinyarwanda' },
  { code: 'sw', name: 'Kiswahili' },
  { code: 'tlh', name: 'Klingon' },
  { code: 'kg', name: 'Kongo' },
  { code: 'mfe', name: 'kreol morisien' },
  { code: 'kri', name: 'Krio (Sierra Leone)' },
  { code: 'la', name: 'Latin' },
  { code: 'lv', name: 'latviešu' },
  { code: 'to', name: 'lea fakatonga' },
  { code: 'lt', name: 'lietuvių' },
  { code: 'ln', name: 'lingála' },
  { code: 'loz', name: 'Lozi' },
  { code: 'lua', name: 'Luba-Lulua' },
  { code: 'lg', name: 'Luganda' },
  { code: 'hu', name: 'magyar' },
  { code: 'mg', name: 'Malagasy' },
  { code: 'mt', name: 'Malti' },
  { code: 'mi', name: 'Māori' },
  { code: 'ms', name: 'Melayu' },
  { code: 'pcm', name: 'Nigerian Pidgin' },
  { code: 'no', name: 'norsk' },
  { code: 'nso', name: 'Northern Sotho' },
  { code: 'ny', name: 'Nyanja' },
  { code: 'nn', name: 'nynorsk' },
  { code: 'uz', name: 'o‘zbek' },
  { code: 'oc', name: 'Occitan' },
  { code: 'om', name: 'Oromoo' },
  { code: 'xx-pirate', name: 'Pirate' },
  { code: 'ro', name: 'română' },
  { code: 'rm', name: 'rumantsch' },
  { code: 'qu', name: 'Runasimi' },
  { code: 'nyn', name: 'Runyankore' },
  { code: 'crs', name: 'Seychellois Creole' },
  { code: 'sq', name: 'shqip' },
  { code: 'sk', name: 'slovenčina' },
  { code: 'sl', name: 'slovenščina' },
  { code: 'so', name: 'Soomaali' },
  { code: 'st', name: 'Southern Sotho' },
  { code: 'sr-ME', name: 'srpski (Crna Gora)' },
  { code: 'sr-Latn', name: 'srpski (latinica)' },
  { code: 'su', name: 'Sundanese' },
  { code: 'fi', name: 'suomi' },
  { code: 'sv', name: 'svenska' },
  { code: 'tn', name: 'Tswana' },
  { code: 'tum', name: 'Tumbuka' },
  { code: 'tk', name: 'türkmen dili' },
  { code: 'tw', name: 'Twi' },
  { code: 'wo', name: 'Wolof' },
  { code: 'el', name: 'Ελληνικά' },
  { code: 'be', name: 'беларуская' },
  { code: 'bg', name: 'български' },
  { code: 'ky', name: 'кыргызча' },
  { code: 'kk', name: 'қазақ тілі' },
  { code: 'mk', name: 'македонски' },
  { code: 'mn', name: 'монгол' },
  { code: 'sr', name: 'српски' },
  { code: 'tt', name: 'татар' },
  { code: 'tg', name: 'тоҷикӣ' },
  { code: 'uk', name: 'українська' },
  { code: 'ka', name: 'ქართული' },
  { code: 'hy', name: 'հայերեն' },
  { code: 'yi', name: 'ייִדיש' },
  { code: 'iw', name: 'עברית' },
  { code: 'ug', name: 'ئۇيغۇرچە' },
  { code: 'ur', name: 'اردو' },
  { code: 'ps', name: 'پښتو' },
  { code: 'sd', name: 'سنڌي' },
  { code: 'fa', name: 'فارسی' },
  { code: 'ckb', name: 'کوردیی ناوەندی' },
  { code: 'ti', name: 'ትግርኛ' },
  { code: 'am', name: 'አማርኛ' },
  { code: 'ne', name: 'नेपाली' },
  { code: 'mr', name: 'मराठी' },
  { code: 'hi', name: 'हिन्दी' },
  { code: 'bn', name: 'বাংলা' },
  { code: 'pa', name: 'ਪੰਜਾਬੀ' },
  { code: 'gu', name: 'ગુજરાતી' },
  { code: 'or', name: 'ଓଡ଼ିଆ' },
  { code: 'ta', name: 'தமிழ்' },
  { code: 'te', name: 'తెలుగు' },
  { code: 'kn', name: 'ಕನ್ನಡ' },
  { code: 'ml', name: 'മലയാളം' },
  { code: 'si', name: 'සිංහල' },
  { code: 'lo', name: 'ລາວ' },
  { code: 'my', name: 'မြန်မာ' },
  { code: 'km', name: 'ខ្មែរ' },
  { code: 'chr', name: 'ᏣᎳᎩ' }
];

export const appLanguages = [
  { code: 'en', label: 'English' },
  { code: 'de', label: 'Deutsch' },
  { code: 'es', label: 'Español' },
  { code: 'fr', label: 'Français' },
  { code: 'it', label: 'Italiano' },
  { code: 'pt-BR', label: 'Português (Brasil)' },
  { code: 'ru', label: 'Русский' },
  { code: 'tr', label: 'Türkçe' },
  { code: 'uk', label: 'Українська' },
  { code: 'zh', label: '中文' },
  { code: 'ar', label: 'العربية' },
  { code: 'hi', label: 'हिन्दी' },
  { code: 'ko', label: '한국어' },
  { code: 'pl', label: 'Polski' },
  { code: 'jp', label: '日本語' },
  { code: 'th', label: 'ไทย' },
  { code: 'vi', label: 'Tiếng Việt' }
];
