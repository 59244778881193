import React from 'react';
import PropTypes from 'prop-types';
import { Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { languagesTop, languagesOther } from '@shared/data/languages';
import InfoTooltip from '@shared/Components/InfoTooltip';

const { Option, OptGroup } = Select;
const languagesTopOptions = languagesTop.map(r => <Option key={r.code} value={r.code}>{r.name} ({r.code})</Option>);
const languagesOtherOptions = languagesOther.map(r => <Option key={r.code} value={r.code}>{r.name} ({r.code})</Option>);

function LanguageSelect({ value, onChange, disabled }) {
  const { t } = useTranslation();

  return <Space align='center' className='first-space-item-full'>
    <Select
      disabled={disabled}
      showSearch
      value={value}
      optionFilterProp='children'
      onChange={onChange}
    >
      <OptGroup label={t('title.common')}>{languagesTopOptions}</OptGroup>
      <OptGroup label={t('title.others')}>{languagesOtherOptions}</OptGroup>
    </Select>
    <InfoTooltip title={t('description.language')}/>
  </Space>;
}

LanguageSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default React.memo(LanguageSelect, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
