import React from 'react';
import SimpleService from '@Common/SimpleService';

export default function BestbuyReviewsScraper() {
  return (
    <SimpleService
      unitName='review'
      title='BestBuy Reviews Scraper'
      startTaskButtonTitle='Export Reviews'
      subTitle='Returns reviews from a list of Bestbuy products'
      serviceName='bestbuy_reviews'
      queriesPlaceholder='https://www.bestbuy.com/site/reviews/12345&#10;https://www.bestbuy.com/site/category/laptops&#10;https://www.bestbuy.com/site/search?q=best+tv'
      apiTag='Bestbuy/paths/~1bestbuy-reviews/get'
      defaultParams={{ sort: 'relevancy' }}
      recentCutoffSorting={'recent'}
      sortingOptions={[
        { value: 'relevancy', text: 'Most Relevant' },
        { value: 'recent', text: 'Most Recent' },
        { value: 'highest_rating', text: 'Highest Rating' },
        { value: 'lowest_rating', text: 'Lowest Rating' },
      ]}
    />
  );
}
