import React from 'react';
import SimpleService from '@Common/SimpleService';

export default function OfficeDepotProducts() {
  return (
    <SimpleService
      unitName='product'
      title='Office Depot Products Scraper'
      subTitle='Returns product listings'
      serviceName='officedepot_products'
      learnMoreUrl='https://outscraper.com/officedepot-scraper/'
      apiTag='OfficeDepot/paths/~1officedepot~1products/get'
      queriesLabel='Product, category or search URLs'
      queriesPlaceholder='https://www.officedepot.com/a/products/304574/Office-Depot-Brand-Manila-File/&#10;https://www.officedepot.com/a/browse/office-supplies/N=5+1676/&#10;https://www.officedepot.com/catalog/search.do?Ntt=desk+chair'
    />
  );
}
