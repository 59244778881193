import React from 'react';
import SimpleService from '@Common/SimpleService';

export default function EtsyProducts() {
  return (
    <SimpleService
      unitName='product'
      title='Etsy Products Scraper'
      subTitle='Returns product listings'
      serviceName='etsy_products'
      learnMoreUrl='https://outscraper.com/etsy-scraper/'
      apiTag='Etsy/paths/~1etsy~1products/get'
      queriesLabel='Product, category or search URLs'
      queriesPlaceholder='https://www.etsy.com/listing/12345&#10;https://www.etsy.com/shop/ExampleShop&#10;https://www.etsy.com/search?q=handmade+bags'
    />);
}
