import React from 'react';
import ReactDOM from 'react-dom/client';
import { Spin } from 'antd';

import './index.scss';
import '@assets/styles/dark-theme.scss';
import '@config';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Mixpanel from '@analytics/mixpanel';
import GA from '@analytics/ga';
import { BrowserRouter as Router } from 'react-router-dom';
import 'antd/dist/reset.css';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <React.Suspense fallback={<div className='center'><Spin /></div>}>
      <App />
    </React.Suspense>
  </Router>
);

serviceWorker.unregister();
Mixpanel.init();
GA.init();
