export const CACHE_CONFIG = {
  DEFAULT_EXPIRATION: 60 * 60 * 1000,
  SETTINGS_EXPIRATION: 60 * 60 * 1000,
  INTEGRATIONS_EXPIRATION: 2 * 60 * 60 * 1000,
};

export const getCachedData = (key, expirationTime = CACHE_CONFIG.DEFAULT_EXPIRATION) => {
  try {
    const cachedItem = localStorage.getItem(key);

    if (!cachedItem) {
      return null;
    }

    const { data, timestamp } = JSON.parse(cachedItem);
    const now = new Date().getTime();

    if (now - timestamp > expirationTime) {
      return null;
    }

    return data;
  } catch (error) {
    console.error(`Error retrieving cached data for ${key}:`, error);
    return null;
  }
};

export const setCachedData = (key, data) => {
  try {
    const cacheItem = {
      data,
      timestamp: new Date().getTime()
    };

    localStorage.setItem(key, JSON.stringify(cacheItem));
  } catch (error) {
    console.error(`Error caching data for ${key}:`, error);
  }
};

export const clearCachedData = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error clearing cached data for ${key}:`, error);
  }
};

export const CACHE_KEYS = {
  USER_SETTINGS: 'cached_user_settings',
  INTEGRATIONS: 'cached_integrations'
};
